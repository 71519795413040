import { TabScrollButton } from '@mui/material';
import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

// @see https://github.com/mui/material-ui/blob/015548243aa133acb445d2f11cd27c02a2058099/
// packages/mui-material/src/TabScrollButton/TabScrollButton.js

export function LeftArrow() {
  const { initComplete, isFirstItemVisible, scrollPrev, visibleElements } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <TabScrollButton
      disabled={disabled}
      onClick={() => scrollPrev()}
      direction="left"
      orientation="horizontal"
    />
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(!visibleElements.length && isLastItemVisible);
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <TabScrollButton
      disabled={disabled}
      onClick={() => scrollNext()}
      direction="right"
      orientation="horizontal"
    />
  );
}
