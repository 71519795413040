import { gql, useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import React from 'react';

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        opt_outs {
          reason
          explanation
          analytic {
            name
            version
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  headerRow: {
    '& > *': {
      borderBottomColor: "rgba(255, 255, 255, 0.2)",
      fontWeight: 'bold'
    }
  },
  high_negative: {
    color: '#4caf50'
  },
  high_positive: {
    color: '#ef5350'
  },
  neutral: {
    color: 'rgba(255, 255, 255, 0.2)'
  },
  questionable: {
    color: '#ffc107'
  },
  row: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const OptOutsTable = ({ name, namespace }) => {
  const classes = useStyles();
  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      name,
      namespace
    }
  });

  const opt_outs = get(data, 'artifactGraphs.data[0].opt_outs', []);

  return (
    <>
      {error && <pre>Error: {JSON.stringify(error, null, 2)}</pre>}
      {loading && <p>loading...</p>}
      {!loading && (
        <Paper>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell>Analytic</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Explanation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {opt_outs.map((score, idx) => {
                  if (score.explanation !== '') {
                    return (
                      <TableRow className={classes.row} key={idx}>
                        <TableCell>{score.analytic.name}</TableCell>
                        <TableCell>{score.reason}</TableCell>
                        <TableCell>{score.explanation}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
};
export default OptOutsTable;
