/* eslint-disable simple-import-sort/imports */
/* eslint-disable no-unused-vars */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SettingsIcon from '@mui/icons-material/Settings';
import ShareIcon from '@mui/icons-material/Share';

import ConnectionSelector from './../components/ConnectionSelector';
import DragAndDropVisualizer from './../components/DragAndDropVisualizer';
import GraphTable from './../components/GraphTable';
import IngestLink from './../components/IngestLink';
import WorkflowTable from './../components/tables/WorkflowTable';
import TwoPanelMediaBrowser from './../components/TwoPanelMediaBrowser';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CodeIcon from '@mui/icons-material/Code';
import IngestAOMs from '../components/IngestAOMs';
import IngestStepper from '../components/IngestStepper';
import AnalyticsCatalog from '../components-v2/AnalyticsCatalog';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import GalleryTable from '../components-v2/GalleryTable';

const menus = [
  {
    name: 'Artifact Graphs',
    subMenu: [
      {
        component: GalleryTable,
        icon: <ImageSearchIcon />,
        name: 'Media Gallery',
        nav: {
          menu: '/gallery',
          route: '/gallery'
        },
        noAccessHidden: true,
        roles: ['hmi-access']
      },
      {
        component: TwoPanelMediaBrowser,
        icon: <ImageSearchIcon />,
        name: 'Media Gallery',
        nav: {
          route: '/gallery/:agNamespace?/:agId?/:tabIndex?/:workflowId?'
        },
        noAccessHidden: true,
        roles: ['hmi-access']
      },
      {
        component: TwoPanelMediaBrowser,
        icon: <ImageSearchIcon />,
        name: 'Media Gallery DataCollections',
        nav: {
          // eval-3/datacollections/namespace/eval-2/name/dc-attribution-verif-org-swap-rev0
          route: '/datacollections/namespace/:dataCollectionNamespace/name/:dataCollectionId/:agNamespace?/:agId?/:tabIndex?/:workflowId?'
        },
        noAccessHidden: true,
        roles: ['hmi-access']
      }
    ]
  },
  {
    name: 'Analytics',
    subMenu: [
      {
        component: AnalyticsCatalog,
        icon: <MenuBookIcon />,
        name: 'Analytics Catalog',
        nav: {
          menu: '/analytics-catalog',
          route: '/analytics-catalog'
        },
        noAccessHidden: true,
        roles: ['hmi-access']
      }
    ]
  },
  {
    name: 'Ingest',
    subMenu: [
      // {
      //   component: IngestStepper,
      //   name: 'Ingest',
      //   nav: {
      //     menu: false,
      //     route: '/ingest/:ingestType?'
      //   }
      // },
      {
        component: IngestStepper,
        icon: <AddToPhotosIcon />,
        name: 'Ingest Wizard',
        nav: {
          menu: '/ingest',
          route: '/ingest'
        },
        noAccessHidden: true,
        roles: ['hmi-disable']
      },
    ]
    //   {
    //     component: null,
    //     icon: <CropOriginalIcon />,
    //     name: 'AOM Builder',
    //     nav: {
    //       menu: '/',
    //       route: '/'
    //     },
    //     roles: ['ingest_image_caption']
    //   },
    //   {
    //     icon: <LinkIcon />,
    //     name: 'Supported URLs',
    //     nav: {
    //       menu: '/ingest/urls'
    //     },
    //     roles: []
    //   },
    //   {
    //     component: null,
    //     icon: <CodeIcon />,
    //     name: 'Markdown',
    //     nav: {
    //       menu: '/',
    //       route: '/'
    //     },
    //     roles: ['ingest_markdown']
    //   }
    // ]
  },
  {
    name: 'Prioritization',
    subMenu: [
      {
        icon: <RecentActorsIcon />,
        name: 'Interest Profiles',
        nav: {
          menu: '/prioritization',
          route: '/prioritization'
        },
        noAccessHidden: true,
        roles: ['hmi-disable']
      }
    ]
  },
  {
    name: 'Engineering',
    subMenu: [
      {
        component: DragAndDropVisualizer,
        icon: <DescriptionIcon />,
        name: 'File Visualizer',
        nav: {
          menu: '/drag-and-drop',
          route: '/drag-and-drop'
        },
        noAccessHidden: true,
        roles: ['hmi-disable']
      },
      {
        component: WorkflowTable,
        icon: <BuildIcon />,
        name: 'Browse Workflows',
        nav: {
          menu: '/workflows',
          route: '/workflows'
        },
        noAccessHidden: true,
        roles: ['hmi-disable']
      }
    ]
  }
];

export default menus;
