import create from 'zustand';

export const useFilterStore = create((set) => ({
  creationDate: Date.now(),
  filter: '',
  fromUser: undefined,
  hasLocalizations: false,
  mmaOnly: false,
  modality: 'ALL',
  setCreationDate: (newDate) => set(() => ({ creationDate: newDate })),
  setFilter: (event) => set(() => ({ filter: event.target.value })),
  setFromUser: (username) => set(() => ({ fromUser: username })),
  setHasLocalizations: (newValue) => set(() => ({  hasLocalizations: newValue })),
  setMmaOnly: (newValue) => set(() => ({ mmaOnly: newValue })),
  setModality: (str) => set(() => ({ modality: str })),
  setUploadedBy: (uploader) => set(() => ({ uploadedBy: uploader })),
  tags: [],
  uploadedBy: null
}));
