import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';

import AllEgFiles from '../../components-v2/AllEgFiles';
import LoadAgGraph from '../../components-v2/LoadAgGraph';
import LocalizationTable from '../../components-v2/LocalizationTable';
import OptOutsTable from '../../components-v2/OptOutsTable';
import ScoresTable from '../../components-v2/ScoresTable';
import TwoPanelScroll from '../../components-v2/TwoPanelScroll';
import WorkflowsTable from '../../components-v2/WorkflowsTable';
import AomViewer from '../AomViewer/AomViewer';
import GraphViewCtyo from '../GraphViewCtyo';
import LoadEgsForAg from '../LoadEgsForAg';
import ScoreViewer from '../ScoreViewer';
import WorkflowsForAgTable from '../tables/WorkflowsForAgTable';

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        aom {
          media_src
          media_preview
          text
          sourceDocumentJson
        }
      }
    }
  }
`;

const AgDetail = (props) => {
  let { agId, agNamespace } = useParams();
  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      name: agId,
      namespace: agNamespace
    }
  });

  const aom = get(data, 'artifactGraphs.data[0].aom', {}); 
  const sourceDocumentJson = get(aom, 'sourceDocumentJson', '');
  const text = get(aom, 'text', '');
  let parsed = "";
  try {
    parsed = JSON.parse(sourceDocumentJson);
  } catch(e) {}
 
  return useMemo(() => {
    if (loading) return 'Loading...';
    if (error) return 'Error...';
    return (
      <Box ml={2} mr={2}>
        <TwoPanelScroll
          pageTitle={text}
          leftComponent={
            <Box m={2}>
                <AomViewer aom={parsed} />
            </Box>
          }
          rightComponent={
            <Box m={2}>

              <Typography variant="h5">Scores</Typography>
              <ScoresTable name={agId} namespace={agNamespace} />

              <br />

              <Typography variant="h5">Opt-Outs</Typography>
              <OptOutsTable name={agId} namespace={agNamespace} />

              <br />

              <Typography variant="h5">Localizations</Typography>
              <LocalizationTable name={agId} namespace={agNamespace}/>

              <br />

              <Typography variant="h5">Files</Typography>
              <AllEgFiles name={agId} namespace={agNamespace} />

              <br />

              {/* <Typography variant="h5">Workflows</Typography>
              <WorkflowsTable name={agId} namespace={agNamespace} /> */}

              
              <Typography variant="h5">Scores w/ Details</Typography>
              <LoadEgsForAg
                agId={agId}
                agNamespace={agNamespace}
                ag={{}}
                renderComponent={ScoreViewer}
              />

              <div style={{ border: '1px solid gray', height: 400, position: 'relative', width: '100%' }}>
                <LoadAgGraph
                  namespace={agNamespace}
                  name={agId}
                  renderComponent={(props) => <GraphViewCtyo full {...props} />}
                />
              </div>

              <Typography variant="h5">Workflows</Typography>
              <WorkflowsForAgTable agId={agId} agNamespace={agNamespace} />
              
            </Box>
          }
        />
      </Box>
    );
  }, [loading, error, text, parsed, agId, agNamespace]);
};

export default AgDetail;
