import create from 'zustand';

export const useLocalizationStore = create((set) => ({
  endTime: undefined,
  hole: undefined,
  localizationType: undefined,
  points: undefined,
  resetLocalizationStore: () => set(() => ({
    endTime: undefined,
    hole: undefined,
    localizationType: undefined,
    points: undefined,
    startIndex: undefined,
    startTime: undefined,
    startUUID: undefined,
    stopIndex: undefined,
    stopUUID: undefined,
    
  })),
  setEndTime: (newValue) => set(() => ({endTime: newValue})),
  setHole: (newValue) => set(() => ({hole: newValue})),
  setLocalizationType: (type) => set(() => ({ localizationType: type})),
  setPoints: (newValue) => set(() => ({points: newValue})),
  setStartIndex: (newValue) => set(() => ({startIndex: newValue})),
  setStartTime: (newValue) => set(() => ({startTime: newValue})),
  setStartUUID: (newValue) => set(() => ({startUUID: newValue})),
  setStopIndex: (newValue) => set(() => ({stopIndex: newValue})),
  setStopUUID: (newValue) => set(() => ({stopUUID: newValue})),
  startIndex: undefined,
  startTime: undefined,
  startUUID: undefined,
  stopIndex: undefined,
  stopUUID: undefined,
}));