import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';
import React, { useContext } from 'react';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const QUERY = gql`
  query ($name: String, $namespace: String, $analytics: [String]) {
    artifactGraphs(limit: 1, where: { name: $name, namespace: $namespace }) {
      data {
        id
        graph(limit_by_analytics: $analytics) {
          nodes {
            id
            nodeType
          }
          edges {
            id
            source
            target
            edgeType
          }
        }
      }
    }
  }
`;

const LoadAgGraph = ({ id, name, namespace, renderComponent: RenderComponent }) => {
  const { collectionIds } = useContext(AnalyticsContext);
  const { data, loading } = useQuery(QUERY, {
    variables: {
      analytics: collectionIds,
      limit: 1,
      name,
      namespace
    }
  });

  const graph = get(data, 'artifactGraphs.data[0].graph', null);

  if (loading || !graph) return null;
  return (
    <RenderComponent
      id={name}
      layout={{
        name: 'dagre',
        nodeDimensionsIncludeLabels: true
      }}
      nodes={graph.nodes}
      edges={graph.edges}
    />
  );
};

export default LoadAgGraph;
