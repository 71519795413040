import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import TheatersIcon from '@mui/icons-material/Theaters';
import clsx from 'clsx';
import mimeTypes from 'mime-types';
import React, { useMemo } from 'react';

import styles from './AOMContentSmartPreview.module.css';

const AOMContentSmartPreview = ({ media_preview, media_src, sequestered = false, text}) => {
  const type = mimeTypes.lookup(media_src) || "";
  return useMemo(
    () => (
      <div className={clsx({ [styles.wrapper]: true, [styles.sequestered]: sequestered })}>
        {media_preview && type.indexOf('image/') === 0 && (
          <img
            alt=""
            className={styles.imgWrapper}
            src={media_preview}
          />
        )}
        {media_preview && type.indexOf('audio/') === 0 && (
          <div className={styles.mediaWrapper}>
            <AudiotrackIcon />
          </div>
        )}
        {media_preview && type.indexOf('video/') === 0 && (
          <div className={styles.mediaWrapper}>
            <TheatersIcon />
          </div>
        )}
        <p className={styles.title}>
          {sequestered ? 'This AG is sequestered and not viewable. Sorry!' : text}
        </p>
        {/* {firstPara && <p className={styles.firstPara}>{firstPara}</p>} */}
        {/* {tags && <span>
        {tags.map((tag) =>
          <Chip
            key={tag}
            variant="outlined"
            size="small"
            label={tag}
          />)
        }</span>} */}
        {/* {firstPara && <span>{firstPara}</span>} */}
      </div>
    ),
    [media_preview, sequestered, text, type]
  );
};

export default AOMContentSmartPreview;
