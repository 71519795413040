import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';

const queryClient = new QueryClient();
const { REACT_APP_DEFAULT_KECLOAK_CLIENT_ID } = process.env;
const clientId = REACT_APP_DEFAULT_KECLOAK_CLIENT_ID || "hmi-staging"

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${clientId}/gallery`} />
          </Route>
          {/* <Redirect 
            from="/:clientId/datacollections/namespace/:agNamespace/name/:agId" 
            to={"/:clientId/gallery/:agNamespace/:agId"} 
          /> */}
          <Route path="/:clientId">
            <SettingsProvider>
              <App />
            </SettingsProvider>
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
