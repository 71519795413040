import { useTheme } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';

const { REACT_APP_BACKEND_ENDPOINT } = process.env;

const ValidGraphChecker = (props) => {
  const theme = useTheme();
  const { combinedGraphs, graph, id, textOnly } = props;
  let _id = 'validate';
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'POST'
  };
  if (graph) {
    _id = `validate-${graph.metadata.name}`;
    options.body = JSON.stringify({
      graphs: [graph]
    });
  }
  if (combinedGraphs) {
    // @todo make this more robust
    _id = `validate-egs-for-ag-${id}`;
    options.body = JSON.stringify({
      graphs: combinedGraphs
    });
  }
  const url = `${REACT_APP_BACKEND_ENDPOINT}/validate`;
  const { data, error, isLoading } = useQuery(_id, () =>
    fetch(url, options).then((res) => res.json())
  );

  if (error) {
    return (
      <>
        <span>Validation service is not running.</span>{' '}
        <a href="https://confluence.semaforprogram.com/x/WIRI">Learn how to fix this here</a>
      </>
    );
  }

  console.log(data);

  let color = 'gray';

  if (data && data.isValid) {
    color = theme.palette.success.main;
  }

  if (data && !data.isValid) {
    color = theme.palette.error.main;
  }

  const result = (
    <>
      {isLoading && 'VALIDATING...'}
      {data && <span>{data.isValid ? 'Valid' : 'INVALID'}</span>}
    </>
  );

  if (textOnly) {
    return (
      <span
        style={{
          color
        }}
      >
        {result}
      </span>
    );
  }

  if (isLoading) return 'VALIDATING...';

  return (
    <div
      style={{
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <h2>
        Validation Report:{' '}
        <span
          style={{
            color
          }}
        >
          {result}
        </span>
      </h2>
      {!data.isValid && !textOnly && (
        <pre
          style={{
            backgroundColor: theme.palette.action.disabledBackground,
            border: '1px dotted gray',
            borderRadius: 2,
            margin: 0,
            padding: 10
          }}
          dangerouslySetInnerHTML={{ __html: data.message }}
        />
      )}
    </div>
  );
};

export default ValidGraphChecker;
