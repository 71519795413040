import MenuIcon from "@mui/icons-material/Menu"
import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { useContext } from "react";

import { NavDrawerContext } from "../../contexts/NavDrawerContext";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 18,
  }
}));

const NavDrawerToggle = () => {
  const classes = useStyles();
  const { open, toggleOpen } = useContext(NavDrawerContext);
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={toggleOpen}
      edge="start"
      className={clsx(classes.menuButton, {
        [classes.hide]: open,
      })}
      size="large">
      <MenuIcon />
    </IconButton>
  );
};

export default NavDrawerToggle;
