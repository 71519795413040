import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';

const ScrollableGrid = styled(Grid)(() => ({
  maxHeight: '91vh',
  minHeight: '91vh',
  overflow: 'auto',
  position: 'relative'
}));

const ScrollableGridDark = styled(ScrollableGrid)(() => ({
  backgroundColor: '#e2e2e231'
}));

const TwoPanelScroll = ({ leftComponent, pageTitle, rightComponent }) => {
  return (
    <Grid container>
      <ScrollableGrid item xs={6}>{leftComponent}</ScrollableGrid>
      <ScrollableGridDark item xs={6}>{rightComponent}</ScrollableGridDark>
    </Grid>
  );
};

export default TwoPanelScroll;
