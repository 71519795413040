/* eslint-disable sort-keys-fix/sort-keys-fix */
import { gql, useQuery } from "@apollo/client";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Paper, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from '@mui/styles/makeStyles';
import get from "lodash/get";
import React from "react";

import { applyScoreStyling } from "./helpers";

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        scores {
          __typename
          value
          score_type
          value_type
          analytic {
            name
            version
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  headerRow: {
    "& > *": {
      fontWeight: "bold",
      borderBottomColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  row: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  high_positive: {
    color: "#ef5350",
  },
  questionable: {
    color: "#ffc107",
  },
  neutral: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  high_negative: {
    color: "#4caf50",
  },
  scores_table: {
    '&::-webkit-scrollbar': {
      width: '0.8em'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(54, 60, 92)',
      borderRadius: '2em'
    },
    overflowY: 'scroll',
  },
});

const ScoresTable = ({ name, namespace }) => {
  const classes = useStyles();
  // let urlParams = useParams();
  // const { namespace, name } = urlParams;
  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      namespace,
      name,
    },
    // fetchPolicy: "no-cache",
  });

  const scores = get(data, "artifactGraphs.data[0].scores", []);

  return (
    <Paper className={classes.scores_table}>
      {error && <pre>Error: {JSON.stringify(error, null, 2)}</pre>}
      {loading && <p>loading...</p>}
      {!loading && (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>Analytic</TableCell>
                <TableCell>Score Type</TableCell>
                {/* <TableCell>Value Type</TableCell> */}
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scores.map((score, idx) => (
                <TableRow className={classes.row} key={idx}>
                  <TableCell>{score.analytic.name}</TableCell>
                  <TableCell>{score.__typename.replace('Score', '')}</TableCell>
                  {/* <TableCell>{score.value_type}</TableCell> */}
                  <TableCell className={classes[applyScoreStyling(score.value)]}>
                    <Tooltip title={`LLR Score: ${score.value}`} arrow>
                      <Brightness1Icon/>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};
export default ScoresTable;
