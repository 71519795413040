import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import React, { useCallback, useContext, useMemo } from 'react';

import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const ToggleAnalytic = (props) => {
  const { id } = props;
  const { addToCollection, isInCollection, removeFromCollection } = useContext(AnalyticsContext);
  const isCollected = useMemo(() => isInCollection(id), [id, isInCollection]);
  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (isCollected) {
        removeFromCollection(id);
      } else {
        addToCollection(id);
      }
    },
    [addToCollection, id, isCollected, removeFromCollection]
  );
  return useMemo(
    () => (
      <IconButton
        size="small"
        onClick={(event) => handleClick(event)}
        className={clsx({
          selected: isCollected
        })}
      >
        {isCollected ? <StarIcon /> : <StarBorderIcon />}
      </IconButton>
    ),
    [handleClick, isCollected]
  );
};

export default ToggleAnalytic;
