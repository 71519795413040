import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { TokenContext } from './TokenContext';
export const DatasetContext = createContext({});

export function DatasetContextProvider({ children }) {
  const { token } = useContext(TokenContext.Dynamic);
  const [dataset, setDataset, clearDataset] = useLocalStorage('dataset', '');

  useEffect(() => {
    // TODO: Once DatasetSelect is verifyed, transition to using API call
    console.log("FETCH DATASETS FROM API")
    // if(token){
    //   fetch("https://gym-api.semaforprogram.com/datasets", {
    //     headers: {
    //       'Authorization': `Bearer ${token}`
    //     }
    //   })
    //   .then((res) => {
    //     console.log(res)
    //     return res.json()
    //   })
    //   .then((data) => {
    //     console.log("DATASETS API", data)
    //   })
    // }
  }, [token])

  return useMemo(
    () => (
      <DatasetContext.Provider
        value={{
          clearDataset,
          dataset,
          setDataset
        }}
      >
        {children}
      </DatasetContext.Provider>
    ),
    [clearDataset, dataset, setDataset, children]
  );
}

DatasetContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
