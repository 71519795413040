import { useKeycloak } from '@react-keycloak/web';
import React, { createContext, useCallback, useMemo,useState } from 'react';
import { useParams } from 'react-router-dom';

import { useUpdatingRef } from '../hooks/useUpdatingRef';

const TokenContext = {
  Dynamic: createContext({}),
  Static: createContext({})
};

const StaticProvider = ({ children, token }) => {
  const tokenRef = useUpdatingRef(token);

  const value = useMemo(() => ({ tokenRef }), [tokenRef]);

  return useMemo(
    () => (
      <TokenContext.Static.Provider value={value}>{children}</TokenContext.Static.Provider>
    ),
    [children, value]
  );
};

const DynamicProvider = ({ children }) => {
  const { keycloak } = useKeycloak();
  const { clientId } = useParams();
  const [token, setToken] = useState(keycloak.token);
  
  const setTokenCallback = useCallback(() => {
    setToken(keycloak.token);
    localStorage.setItem(`${clientId}.token`, keycloak.token);
    console.log(`${clientId}.token`, keycloak.token);
}, [keycloak.token, clientId])

  keycloak.onAuthRefreshSuccess = setTokenCallback;
  keycloak.onReady = setTokenCallback;
 
  const value = useMemo(() => {
    return { token };
  }, [token]);

  return useMemo(
    () => (
      <TokenContext.Dynamic.Provider value={value}>
        <StaticProvider token={token}>{children}</StaticProvider>
      </TokenContext.Dynamic.Provider>
    ),
    [children, token, value]
  );
};

export { TokenContext, DynamicProvider as TokenProvider };
