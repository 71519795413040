import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { find, get, isEmpty, reduce } from 'lodash';
import mimeTypes from 'mime-types';
import React, { useContext, useEffect, useState} from 'react';
import { useElementSize } from 'usehooks-ts';

import { useLocalizationStore } from '../../components-v2/LocalizationTable/localizationStore';
import { TokenContext } from '../../contexts/TokenContext';
import useMinioToAppServer from '../../helpers/useMinioToAppServer';
import MediaPlayer from '../MediaPlayer';
import MetadataViewer from './../MetadataViewer';

const loadImage = (setImageDimensions, imageUrl) => {
  const image = new Image()
  image.src = imageUrl

  image.onload = () => {
    setImageDimensions({
      height: image.height,
      width: image.width
    });
  };
  image.onerror = (err) =>{
    console.log("Image load error")
    console.error(err)
  }
}

const AomViewer = (props) => {
  const [previewRef, {height, width}] = useElementSize()
  const { heatMapURL, points, startIndex, startUUID, stopIndex, stopUUID } = useLocalizationStore();
  const urlMaker = useMinioToAppServer();
  const { token } = useContext(TokenContext.Dynamic);
  const [imageDimensions, setImageDimensions] = useState()

  const { aom } = props;

  const [state] = useState({
    showIds: true,
    showMetadata: false
  });

  let imgSrc = undefined;

  useEffect(() => {
    loadImage(setImageDimensions,imgSrc)
    console.log("Image dimensions:" + JSON.stringify(imageDimensions))
  }, [imgSrc])

  const allImages = reduce(
    aom.figures,
    (acc, fig) => {
      return [...acc, ...fig.media];
    },
    []
  );



  const processPolyPoints = (points, imageDimensions, width) => {

    // Points come in from the localization store like this:
    // [{ x:Number, y:Number }, { x:Number, y:Number}, ... ]
    // And we need to scale them and then convert them to an array and return like this:
    // ["Number,Number","Number,Number", ... ]

    if (points == null || imageDimensions == null || width == null) 
      return [] 

    let currentwidth = Math.min(1000,width)

    // This is the new scale
    let scaleRatio = (Number(currentwidth/imageDimensions.width));
    //console.log("Ratio is " + scaleRatio);
    let newPoints = []
    //Multiply all points by this scale to generate a new set of points for the bounding polygon
    points.forEach(element => {

      let newWidth = Number(element.x) * scaleRatio;
      let newHeight = Number(imageDimensions.height - element.y) * scaleRatio;

      newPoints.push(String(newWidth)+","+String(newHeight));
    });
    console.log("Original points: " + JSON.stringify(points));
    console.log("New scaled points: " + JSON.stringify(newPoints));
    return newPoints;
  }
  function TestImageInfo(props) {
    if (!imageDimensions  || Object.keys(imageDimensions).length === 0 ) {
     return <b>Calculating...</b>
    } else {
      return (
      <>
        <p>
          <b>Height:</b> {imageDimensions?.height}{" "}
        </p>
        <p>
          <b>Width:</b> {imageDimensions?.width}{" "}
        </p>
        <p>
          <b>Current Width:</b> {width}{" "}
        </p>
      </> )
    }
  }
  function formatter(c) {
    return (
      <React.Fragment>
        {state.showIds && <Chip variant="outlined" size="small" label={c.ID} />}
        {c.Type === 'Link' && (
          <a href={c.Uri} target="_blank" rel="noreferrer">
            {recurseContent(c)}
          </a>
        )}
        {c.Type === 'Text' && <p>{recurseContent(c)}</p>}
      </React.Fragment>
    );
  }

  function recurseContent(c, type) {
    return c.Content.map((subC) => {
      if (!subC.ID) {
        return <React.Fragment key={subC}>{subC}</React.Fragment>;
      } else {
        return <React.Fragment key={subC.ID || subC}>{formatter(subC)}</React.Fragment>;
      }
    });
  }

  let testPoints = []
  testPoints.push("1,1");
  testPoints.push("100,100");
  testPoints.push("100,150");
  // @todo video http://localhost:3001/gallery/b584377833be2d7736613e6a9fe92364c720f56efdeaad6bfa386ab789ae671a
  function formatMedia(c) {
    const semaforStoredImage = find(allImages, ({ source_uri, uri }) => {
      return source_uri === c.SourceUri || uri.indexOf(c.SourceUri >= -1);
    });
    if (semaforStoredImage) {
      const uri = urlMaker(semaforStoredImage.uri, token);
      const type = mimeTypes.lookup(semaforStoredImage.uri);
      if (type.indexOf('image/') === 0) { // TODO change this to check the localization type first. 
        imgSrc = uri
        console.log(JSON.stringify(points))
        return (
        <div  ref={previewRef} style ={{alignItems: "center", display:"flex", justifyContent: "center" }}>
        <svg  alt="article" style={{ alignItems: 'center', backgroundImage: 'url('+uri+')', backgroundOrigin: "content-box",  
                      backgroundRepeat: "no-repeat", backgroundSize: "contain", display: 'flex', height: '1000px',  
                      justifyContent: 'center', maxWidth: '100%',  width: '1000px'}} >
         {<polygon style={{fill: "rgba(44,44,244,.05)", stroke:"blue", strokeWidth: "3"}} points={processPolyPoints(points,imageDimensions,width).join(" ")} /> }
        </svg>
        </div>);
      }
      if (type.indexOf('audio/mpeg') === 0) {
        return (
          <div style={{ maxWidth: '50%' }}>
            <MediaPlayer style={{ maxWidth: '50%' }} audio uri={uri} type={type} />
          </div>
        );
      }
      if (type.indexOf('video/mp4') === 0) {
        return (
          <div style={{ maxWidth: '50%' }}>
            <MediaPlayer video uri={uri} type={type} />
          </div>
        );
      }
      return <p style={{ color: 'red' }}>{type} not yet supported</p>;
    }
    return <p style={{ color: 'red' }}>Error with image {c.SourceUri}</p>;
  }

  const AOMLocTextFormat = (text) => {
    return(
      <em>caption: <span style={{backgroundColor: "yellow"}}>{text}</span></em>
    )
  };

  function recurseMedia(c) {
    // idx is intentional
    // @todo debug why this format does not have IDs
    return c.Media.map((subC, idx) => {
      return (
        <React.Fragment key={subC.ID || idx}>
          {formatMedia(subC)}
          <p>
            {startUUID && startUUID.split('.')[1] === 'Caption' ? (
              AOMLocTextFormat(c.Caption)
            ) : (
              <em>caption: {c.Caption}</em>
            )}
          </p>
        </React.Fragment>
      );
    });
  }

  console.log({
    aom
  });

  if (isEmpty(aom)) {
    return null;
  }

  return (
    <>
      {/* <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={state.showMetadata}
              onChange={handleChange}
              name="showMetadata"
              color="primary"
            />
          }
          label="Show Metadata"
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.showIds}
              onChange={handleChange}
              name="showIds"
              color="primary"
            />
          }
          label="Debug AOM IDs"
        />
        <FormControlLabel disabled control={<Switch />} label="Show Localization Evidence" />
        <FormControlLabel disabled control={<Switch />} label="Hide Duplicate Images" />
        <FormControlLabel disabled control={<Switch />} label="Apply Formatting" />
      </FormGroup> */}
      {/* <h1>{aom.title}</h1> */}
      <Typography variant="h4">{aom.title}</Typography>
      {/* <TestImageInfo></TestImageInfo> */}
      <p>
        <strong>Source: {get(aom, 'source.name', '')}</strong>
        {/* <br />
        Source URL:{' '}
        <a href={get(aom, 'uri', '')} rel="noreferrer" target="_blank">
          {get(aom, 'uri', '')}
        </a> */}
      </p>
      {state.showMetadata && <MetadataViewer aom={aom} />}
      {get(aom, 'content', []).map((c) => {
        return (
          <React.Fragment key={c.ID || c} >
            {state.showIds && <Chip variant="outlined" size="small" label={c.ID} />}
            {c.Content && recurseContent(c)}
            {c.Media && recurseMedia(c)}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default AomViewer;
