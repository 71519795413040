import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';
import React from 'react';

import AOMContentSmartPreview from '../../components/AOMContentSmartPreview';

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        aom {
          media_src
          media_preview
          text
          sourceDocumentJson
        }
      }
    }
  }
`;

const GalleryTableRow = ({ name, namespace }) => {
  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      name,
      namespace
    }
  });
  if (loading) return 'Loading...';
  if (error) return 'Error...';
  const aom = get(data, 'artifactGraphs.data[0].aom');
  // if (!aom) return null;
  return (
    <div style={{ cursor: 'pointer', height: '100%', position: 'relative', width: '100%' }}>
      {aom && <AOMContentSmartPreview {...aom} />}
      {!aom && <span>No Preview</span>}
    </div>
  );
};

export default GalleryTableRow;
