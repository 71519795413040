import { Button, FormGroup, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { compact, truncate } from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation, useQueryClient } from 'react-query';

import { AgCollectionContext } from '../../contexts/AgCollectionContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { TagContext } from '../../contexts/TagContext';
import { TokenContext } from '../../contexts/TokenContext';
import useUpdatingRef from '../../hooks/useUpdatingRef';
import HelpButton from '../HelpButton';

const useStyles = makeStyles((theme) => {
  return {
    disabledFormGroup: {
      '> *': {
        cursor: 'not-allowed'
      },
      cursor: 'not-allowed',
      opacity: 0.5,
      pointerEvents: 'none'
    },
    dropArea: {
      marginTop: 50,
      textAlign: 'center'
    },
    dropzone: {
      border: '1px solid lightgray',
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      minHeight: 200,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%'
    },
    helpButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },
    paddedButton: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    paper: {
      height: '100%',
      marginLeft: theme.spacing(2),
      overflow: 'auto',
      position: 'relative'
    },
    paperLeft: {
      height: '100%',
      overflow: 'auto',
      position: 'relative'
    }
  };
});

const IngestPanel = React.forwardRef((props, ref) => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { ingestType, title } = props
  const { collectionIds } = useContext(AnalyticsContext);
  const { collectionIds: agIds, resetCollection } = useContext(AgCollectionContext);
  const { token } = useContext(TokenContext.Dynamic);
  const [files, setFiles] = useState([]);
  const [markdownFiles, setMarkdownFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [url, setUrl] = useState('');
  const [urls, setUrls] = useState('');
  const [fetcher, setFetcher] = useState();

  // @TODO sort on label timestamp
  const imagesRef = useUpdatingRef(images);
  const filesRef = useUpdatingRef(files);
  const markdownFilesRef = useUpdatingRef(markdownFiles);
  const urlRef = useUpdatingRef(url);
  const urlsRef = useUpdatingRef(urls);
  const { tag } = useContext(TagContext);
  const tagRef = useUpdatingRef(tag);
  const tokenRef = useUpdatingRef(token);

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: '	application/zip, application/x-gzip',
    onDrop
  });

  const thumbs = files.map((file) => (
    <li key={file.name}>
      {truncate(file.name, {
        length: 24
      })}
    </li>
  ));

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setFetcher(() => {
        let formData = new FormData();
        // @todo use all for testing, use user selected.
        // const analytics = availableAnalytics.map(({name}) => name).join(',');
        if (collectionIds.length) {
          formData.append('analytics', collectionIds.join(','));
        }
        if (agIds.length) {
          formData.append('existing_ags', agIds.join(','));
        }

        imagesRef.current.forEach((f, i) => {
          formData.append(`image[]`, f, f.name);
        });

        // splits on new lines, commas + newlines
        // could be improved and unit tested :)
        const parsedUrls = compact(urlsRef.current.replace(/,.*\n/g, '\n').split(/\r?\n/));
        if (parsedUrls.length) {
          formData.append('urls', parsedUrls.join(','));
        }
        formData.append('tag', tagRef.current);
        formData.append('token', tokenRef.current);
        // formData.append('namespace', namespaceRef.current);
        formData.append('url', urlRef.current);
        markdownFilesRef.current.forEach((f, i) => {
          formData.append(`markdown[]`, f, f.name);
        });
        filesRef.current.forEach((f, i) => {
          formData.append(`file[]`, f, f.name);
        });
        return fetch(`http://localhost:5000/test`, {
          body: formData,
          // headers: {
          //   'Content-Type': 'multipart/form-data'
          // },
          method: 'POST',
          mode: 'no-cors'
        })
          .then((res) => res.text())
          .then((data) => {
            console.log('data', data);
          })
          .catch((err) => {
            console.log('err', err);
          });
      });
      setFiles([]);
      setImages([]);
      setMarkdownFiles([]);
      resetCollection([]);
      setUrls('');
      setUrl('');
    },
    [agIds, collectionIds, filesRef, imagesRef, markdownFilesRef, resetCollection, tagRef, tokenRef, urlRef, urlsRef]
  );

  // @todo update to use mutation.mutate
  // https://react-query.tanstack.com/guides/mutations
  useMutation(fetcher, {
    onMutate: (newAgs) => {
      // Snapshot the previous value
      const previousTodosPrev = queryClient.getQueryData(['artifactgraphs']);
      console.log(previousTodosPrev);
      // Optimistically update to the new value
      queryClient.setQueryData('artifactgraphs', (old) => [...old, { name: 'test' }]);

      // Return a context object with the snapshotted value
      // return { previousTodos }
    }
  });
  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit} ref={ref}>
      <div className={classes.helpButton}>
        <HelpButton
          size="small"
          url="/docs/visualize-and-validate/how-it-works"
          title="Ingest Help"
        />
      </div>

      <Typography>{title || 'Select media to ingest'}</Typography>

      {/* <Button variant="outlined" onClick={() => setIngestType('aom')} className={classes.paddedButton}>SemaFor AOMs</Button>
        <Button variant="outlined" onClick={() => setIngestType('urls')} className={classes.paddedButton}>URLs</Button> */}

      {ingestType === 'aom' && (
        <FormGroup>
          <section className="container">
            <div {...getRootProps({ className: classes.dropzone })}>
              <input {...getInputProps()} />
              <p className={classes.dropArea}>
                Drag &amp; drop files here or click to browse for files.
              </p>
              <ul>{thumbs}</ul>
            </div>
            {/* <aside style={thumbsContainer}>
              <ul>{thumbs}</ul>
            </aside> */}
          </section>
        </FormGroup>
      )}

      {ingestType === 'url' && (
        <FormGroup>
          <p>
            The URL must be in supported URLs list{' '}
            <a href="https://confluence.semaforprogram.com/x/vIIr" target="blank">
              on Confluence
            </a>
          </p>
          <TextField
            id="outlined-full-width"
            label="URLs"
            placeholder=""
            helperText="Paste 1 or more URLs from supported sources."
            fullWidth
            multiline
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
            value={urls}
            variant="outlined"
            onInput={(e) => {
              setUrls(e.target.value);
            }}
          />
        </FormGroup>
      )}

      <br />
      {!ref && <Button
        size="large"
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={!url && !urls.length && !files.length && !images.length}
      >
        Ingest
      </Button>}
    </form>
  );
});

export default IngestPanel;
