import { gql, useQuery } from '@apollo/client';
import get from 'lodash/get';
import React from 'react';

import FileDownloadTable from '../FileDownloadTable';

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        graph { 
          files: downloadableFiles {
            url
            mimetype
            name
          }
        }
      }
    }
  }
`;

const AllEgFiles = ({ name, namespace }) => {
  const { data, loading } = useQuery(QUERY, {
    variables: {
      name,
      namespace
    }
    // fetchPolicy: "no-cache",
  });

  const files = get(data, 'artifactGraphs.data[0].graph.files', []);

  return <FileDownloadTable files={files} loading={loading} />;
};

export default AllEgFiles;
