import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import React, { useContext } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import { UsernameContext } from '../../contexts/UsernameContext';
import { LeftArrow, RightArrow } from './arrows';
import { useFilterStore } from './filterStore';

// eslint-disable-next-line no-unused-vars
function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const StyledScrollMenu = styled(ScrollMenu)`
  &.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  &.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const SearchChip = styled(Chip)`
  // margin-left: 1em;
  margin-right: 10px;
`;

const GallerySearch = ({ onApply = () => {} }) => {
  const { fromUser, hasLocalizations, mmaOnly, modality, setFromUser, setHasLocalizations, setMmaOnly, setModality } = useFilterStore();
  const { username } = useContext(UsernameContext.Dynamic);

  return (
    <StyledScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      <SearchChip
        color="primary"
        label="My Uploads"
        variant={fromUser === username ? 'default' : 'outlined'}
        onClick={() => {
          if (fromUser) {
            setFromUser(undefined);
          } else {
            setFromUser(username);
          }
        }}
      />

      <SearchChip
        color="primary"
        label="Modality: All"
        variant={modality === 'ALL' ? 'default' : 'outlined'}
        onClick={() => {
          setModality('ALL');
        }}
      />

      <SearchChip
        color="primary"
        variant={modality === 'IMAGE' ? 'default' : 'outlined'}
        label="Modality: Image"
        onClick={() => {
          setModality('IMAGE');
        }}
      />

      <SearchChip
        color="primary"
        variant={modality === 'VIDEO' ? 'default' : 'outlined'}
        label="Modality: Video"
        onClick={() => {
          setModality('VIDEO');
        }}
      />
      <SearchChip
        color="primary"
        variant={modality === 'AUDIO' ? 'default' : 'outlined'}
        label="Modality: Audio"
        onClick={() => {
          setModality('AUDIO');
        }}
      />
      <SearchChip
        color="primary"
        variant={mmaOnly ? 'default' : 'outlined'}
        label="Has Augmented Artifact Graphs"
        onClick={() => {
          setMmaOnly(!mmaOnly);
        }}
      />
      <SearchChip
        color="primary"
        variant={hasLocalizations ? 'default' : 'outlined'}
        label="Has Localizations"
        onClick={() => {
          setHasLocalizations(!hasLocalizations);
        }}
      />
      <SearchChip color="primary" variant="outlined" label="Processed in last 30 days" disabled />
      <SearchChip color="primary" variant="outlined" label="Has Detection score" disabled />
      <SearchChip color="primary" variant="outlined" label="Has Attribution Score" disabled />
      <SearchChip color="primary" variant="outlined" label="Has Characterization Score" disabled />
      <SearchChip color="primary" variant="outlined" label="Has Characterization Score" disabled />
    </StyledScrollMenu>
  );
};

export default GallerySearch;
