import { useMemo } from "react";
import { useLocation } from "react-use";

export const useGraphqlConfig = () => {
  const { hostname } = useLocation();
  return useMemo(() => {
    if (hostname === "localhost") {
      return "https://localhost:5000/graphql";
    }
    if(hostname.includes('-staging.semaforprogram.com')) {
      return "https://hmi-api-staging.semaforprogram.com/graphql";
    }
    return "https://hmi-api.semaforprogram.com/graphql";
  }, [hostname]);
};

export default useGraphqlConfig;
