import { NetworkStatus } from "@apollo/client";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect,useState } from "react";

const useStyles = makeStyles({
  refetchContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    marginTop: "2em"
  },
});

const ManualQuery = ({ disabled=false, networkStatus, refetch }) => {
  const classes = useStyles();
  const [lastFetched, setLastFetched] = useState(new Date());

  useEffect(() => {
    if(networkStatus === NetworkStatus.poll){
      setLastFetched(new Date())
    }
  }, [networkStatus])

  return (
    <div className={classes.refetchContainer}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={disabled}
        onClick={() => {
          refetch();
          setLastFetched(new Date());
        }}
      >
        Check Status
      </Button>
      <span>
        Last checked at:{" "}
        {lastFetched.toLocaleTimeString("en-US", { hour12: false })}
      </span>
    </div>
  );
};

export default ManualQuery;
