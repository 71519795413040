import { chain } from 'lodash';

import { scale } from './constants';

export const applyScoreStyling = (score) => {
  // if score is of type LLR
  const textHasScoreRange = (rangeObj) => {
    return rangeObj.low <= score && rangeObj.high >= score;
  };

  return chain(scale).pickBy(textHasScoreRange).keys().value();
};