import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import AnalyticsCatalogTable from './AnalyticsCatalogTable';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    marginLeft: theme.spacing(2),
    overflow: 'auto',
    position: 'relative'
  },
  paperLeft: {
    height: '100%',
    overflow: 'auto',
    position: 'relative'
  },
  spaceLeft: {
    marginLeft: theme.spacing(2)
  }
}));

const AnalyticsCatalog = (props) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paperLeft}>
          <AnalyticsCatalogTable />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AnalyticsCatalog;
