import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import download from 'downloadjs';
import React from 'react';
const useStyles = makeStyles({
  noFiles: {
    textAlign: 'center'
  }
});

function truncateString(string:string, maxLength:number) {
  if (string.length > maxLength) {
    return string.substr(0, maxLength) + '...' + string.substr(string.length - 10, string.length);
  }
  return string;
}

const FileDownloadTable = ({ files, loading }: {files: any[]; loading: boolean; }) => {
  return (
    <>
      {!loading && files.length > 0 &&  (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {files.map((file) => (
                  <TableRow key={file.url}>
                    <TableCell>{truncateString(file.name, 22)}</TableCell>
                    <TableCell>{file.mimetype}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          fetch(file.url)
                            .then((res) => res.blob())
                            .then((blob) => {
                              download(blob, file.name);
                            });
                        }}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {(!loading && files?.length === 0) && (
        <p>No Files Available To Download</p>
      )}
      {loading && (
        <p>Loading...</p>
      )}
    </>
  );
};

export default FileDownloadTable;
