import React, { useContext } from 'react';
import { useQuery } from 'react-query';

import { SettingsContext } from '../../contexts/SettingsContext';
import { TokenContext } from '../../contexts/TokenContext';
import safeParseApiJson from '../../helpers/safeParseApiJson';

const QueryGraph = (props) => {
  const { name, namespace, renderComponent: RenderComponent } = props;
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const url = `${connection}/namespaces/${namespace}/evidencegraphs/${name}`;
  const { data, error, isLoading } = useQuery([url, token], () =>
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => res.text())
      .then(safeParseApiJson)
  );

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  return <RenderComponent graph={data} nodes={data.nodes} edges={data.edges} id={name} />;
};

export default QueryGraph;
