import Toolbar from "@mui/material/Toolbar";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import TabbedAppBar from "../../components-v2/TabbedAppBar";
import RequestAccess from "../RequestAccess";
import menus from "./../../constants/menus";
import HasAuthRole from "./../HasAuthRole";
import NavDrawerToggle from "./../NavDrawer/NavDrawerToggle";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(
      5
    )})`,
  },
  root: {
    display: "flex",
  },
  routeArea: {
    flexGrow: 1,
    height: '100%',
  },
  // routeArea_drawerClosed: {
  //   marginLeft: theme.spacing(7),
  //   transition: theme.transitions.create('margin-left', {
  //     duration: theme.transitions.duration.enteringScreen,
  //     easing: theme.transitions.easing.sharp
  //   }),
  // },
  // routeArea_drawerOpen: {
  //   flexGrow: 1,
  //   marginLeft: drawerWidth,
  //   transition: theme.transitions.create('margin-left', {
  //     duration: theme.transitions.duration.leavingScreen,
  //     easing: theme.transitions.easing.sharp
  //   }),
  // },
}));

const MainContent = (props) => {
  let { path } = useRouteMatch();
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <TabbedAppBar
        menu={
          <HasAuthRole allRoles={["hmi-access"]}>
            <NavDrawerToggle />
          </HasAuthRole>
        }
      />
      <Toolbar />
      {/* <HasAuthRole allRoles={["hmi-access"]}>
        <NavDrawer />
      </HasAuthRole> */}
      <HasAuthRole allRoles={["hmi-access"]} notAuthorized={
        <RequestAccess />
      }>
        <div>
          <Switch>
            <Route exact path="/">
              <Redirect to="/gallery" />
            </Route>
            {menus
              .reduce((acc, menu) => [...acc, ...menu.subMenu], [])
              .map(
                (menuItem) =>
                  // @todo do this with redirect
                  menuItem.nav.route && (
                    <Route exact key={menuItem.name} path={`${path}${menuItem.nav.route}`}>
                      {menuItem.component && <menuItem.component />}
                    </Route>
                  )
              )}
          </Switch>
        </div>
      </HasAuthRole>
    </main>
  );
};

export default MainContent;
