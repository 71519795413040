import { gql, useQuery } from '@apollo/client';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { get, groupBy, sortBy, trim, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';

import ToggleAnalytic from '../../components/ToggleAnalytic';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';

const QUERY = gql`
  query Analytics($offset: Int, $limit: Int, $filter_mongo: String, $where: ApiWhere) {
    analytics(offset: $offset, limit: $limit, filter_mongo: $filter_mongo, where: $where) {
      data {
        id
        display_name
        name
        summary
        desc
        url
        version
        poc_names
        gpu
        creation_timestamp
        external_resources
      }
      meta {
        count
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    '& > button': {
      opacity: 0.2
    },
    '& > button.selected': {
      opacity: 1
    }
  },
  buttonCell: {
    width: 87
  },
  container: {
    maxHeight: '100%'
  },
  filterButton: {
    '& > button': {
      marginRight: theme.spacing(1)
    },
    marginRight: theme.spacing(2)
  },
  previewCell: {
    padding: '6px 6px 6px 0'
  },
  relative: {
    position: 'relative'
  },
  tableRow: {
    '&:hover button': {
      opacity: 1
    },
    cursor: 'pointer',
    transition: theme.transitions.create('backgroundColor', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp
    })
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: (theme.palette.primary.light, 0.85),
      color: theme.palette.primary.main
    }
  },
  tableRowSelected: {
    backgroundColor: (theme.palette.primary.light, 0.85),
    color: theme.palette.primary.main
  }
}));

const AnalyticsCatalogTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [val, setVal] = React.useState('');
  const [debouncedValue, setDebouncedValue] = React.useState('');
  const [count, setCount] = useState(0);
  const { collectionIds } = useContext(AnalyticsContext);
  const pageSize = 100;
  const [showCollection, toggleShowCollection] = useToggle(false);
  const [offset, setOffset] = useState(0);

  const { data, error, fetchMore } = useQuery(QUERY, {
    variables: {
      limit: 100,
      offset: offset
    }
  });

  useDebounce(
    () => {
      setDebouncedValue(val);
    },
    2000,
    [val]
  );

  // @todo do in more performance optimized way
  // also look at App.js List while doing this. Pair with @riley?
  let filter = {};

  // if (collectionIds && showCollection) {
  //   filter['metadata.name'] = {
  //     $in: collectionIds
  //   };
  // }

  // @todo hook up search for analytics catalog
  // if (debouncedValue && trim(debouncedValue) !== '') {
  //   filter['$or'] = [
  //     { 'metadata.name': { $regex: debouncedValue } },
  //     { 'poc.name': { $regex: debouncedValue } },
  //     { 'metadata.summary': { $regex: debouncedValue } },
  //     { 'metadata.description': { $regex: debouncedValue } }
  //   ];
  // }

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage > page) {
        setOffset((old) => old + 100);
        fetchMore({ variables: { offset: offset } });
        setPage(newPage);
      } else {
        setOffset((old) => old - 100);
        fetchMore({ variables: { offset: offset } });
        setPage(newPage);
      }
    },
    [fetchMore, offset, page]
  );

  useEffect(() => {
    if (!showCollection) {
      setCount(get(data, ['analytics', 'meta', "count"], 0));
    } else {
      // go back to page 0
      setPage(0);
      setOffset(0);
    }
  }, [data, showCollection]);

  const reduced = get(data, ['analytics', 'data'], []).map((row) => {
    const key = [
      get(row, 'name'),
      get(row, 'creation_timestamp'),
      JSON.stringify(get(row, 'status'))
    ];
    const stringVersion = `-${get(row, 'version', '').replaceAll('.', '-')}`;
    const nonVersionName = get(row, 'name', '').replace(stringVersion, '');
    return {
      ...row,
      key,
      nonVersionName
    };
  });

  const grouped = groupBy(reduced, 'nonVersionName');

  const handleSearchChange = useCallback((event) => {
    setVal(event.target.value);
  }, []);

  useEffect(() => {
    setCount(get(data, ['analytics', 'meta', 'count'], 0));
  }, [data, debouncedValue]);

  return useMemo(
    () => (
      <>
        {error && 'An error has occurred: ' + error.message}
        {!error && data && (
          <TableContainer className={classes.container}>
            <Table size="small" aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" className={classes.buttonCell}>
                    <IconButton
                      className={classes.paginationAreaButton}
                      onClick={() => toggleShowCollection()}
                    >
                      {showCollection ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" className={classes.buttonCell} colSpan={2}>
                    <TextField
                      onChange={handleSearchChange}
                      margin="dense"
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                      placeholder="Search name, summary, desc, and POC name"
                    />
                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={11}
                    count={count}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Star</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Summary</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Repo</TableCell>
                  <TableCell>POC Names</TableCell>
                  <TableCell>GPU?</TableCell>
                  <TableCell>External Resources?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(grouped).map((key) => {
                  const previousVersions = uniqBy(
                    sortBy(grouped[key], (d) => Date.parse(get(d, 'creation_timestamp'))).reverse(),
                    'version'
                  );
                  const row = previousVersions[0];
                  return (
                    <React.Fragment key={row.key}>
                      <TableRow key={`${row.key}-latest-version`}>
                        <TableCell className={classes.buttonCell} align="center">
                          <div className={classes.button}>
                            <ToggleAnalytic id={get(row, 'name')} />
                          </div>
                        </TableCell>
                        <TableCell>{get(row, 'name')}</TableCell>
                        <TableCell>{get(row, 'summary')}</TableCell>
                        <TableCell>{get(row, 'desc')}</TableCell>
                        <TableCell>
                          <a href={get(row, 'url')} target="blank">
                            Gitlab
                          </a>
                        </TableCell>
                        <TableCell>{get(row, 'poc_names')}</TableCell>
                        <TableCell>{get(row, 'gpu') ? 'GPU' : ' '} </TableCell>
                        <TableCell>{get(row, 'external_resources') ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                      <TableRow key={`${row.key}-previous-versions`}>
                        <TableCell style={{ margin: 0, padding: 0 }} colSpan={10}>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              {previousVersions.map((r) => (
                                <TableRow
                                  key={`${r.version}-${r.creation_timestamp}`}
                                  style={{ height: 20 }}
                                >
                                  <TableCell className={classes.buttonCell} align="center">
                                    <div className={classes.button}>
                                      <ToggleAnalytic id={get(r, 'id')} />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <span>
                                      {r.version}
                                      <br />
                                    </span>
                                  </TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [
      error,
      data,
      classes.container,
      classes.buttonCell,
      classes.paginationAreaButton,
      classes.button,
      showCollection,
      handleSearchChange,
      count,
      page,
      handleChangePage,
      grouped,
      toggleShowCollection
    ]
  );
};

export default AnalyticsCatalogTable;
