import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

// @note we are connecting to matt2 namespace app-server for now.
// keep this in here and make configurable in future
// const { REACT_APP_APPSERVER_ENDPOINT } = process.env;

export const SettingsContext = createContext({});

export function SettingsProvider({ children }) {
  const { clientId } = useParams();

  const DEFAULT_CONNECTION = 'https://api.hmi-staging.compute.semaforprogram.com';

  const [connection, setConnection] = useState('');

  useEffect(() => {
    console.log('setting DEFAULT_CONNECTION', clientId)
    setConnection(DEFAULT_CONNECTION.replace('hmi-staging', clientId));
  }, [clientId]);

  return useMemo(
    () => (
      <SettingsContext.Provider
        value={{
          clientId,
          connection
        }}
      >
        {children}
      </SettingsContext.Provider>
    ),
    [clientId, connection, children]
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
