/* eslint-disable sort-keys-fix/sort-keys-fix */
export const scale = {
  high_positive: {
    high: Infinity,
    low: 0.5000000000000000001
  },
  questionable: {
    high: 0.5,
    low: 0.2000000000000000001
  },
  neutral: {
    high: 0.2,
    low: -0.5
  },
  high_negative: {
    high: -0.5000000000000000001,
    low: -Infinity
  }
};