import SortIcon from '@mui/icons-material/Sort';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import IconButton from '@mui/material/IconButton';
// import { lighten } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { find, get, reduce, size, trim } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import DATASETS from '../../../constants/gym-datasets.json';
import { AgCollectionContext } from '../../../contexts/AgCollectionContext';
import { CollectionContext } from '../../../contexts/CollectionContext';
import { CompetitionContext } from '../../../contexts/CompetitionContext';
import { DatasetContext } from '../../../contexts/DatasetContext';
import { NamespaceContext } from '../../../contexts/NamespaceContext';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { TagContext } from '../../../contexts/TagContext';
import { TokenContext } from '../../../contexts/TokenContext';
import safeParseApiJson from '../../../helpers/safeParseApiJson';
import CompetitionSelect from '../../CompetitionSelect';
import LoadEgsForAg from '../../LoadEgsForAg';
import { ScoreViewerSimpleDots } from '../../ScoreViewerSimple';
import AOMContentSmartPreview from './../../AOMContentSmartPreview';
import LoadAOMForAg from './../../LoadAOMForAg';
import ToggleCollection from './../../ToggleCollection';

const useStyles = makeStyles((theme) => ({
  button: {
    '& > button': {
      opacity: 0.2
    },
    '& > button.selected': {
      opacity: 1
    }
  },
  buttonCell: {
    padding: '0 6px !important'
  },
  container: {
    maxHeight: '100%'
  },
  // filterButton: {
  //   '& > button': {
  //     marginRight: theme.spacing(1)
  //   },
  //   marginRight: theme.spacing(1)
  // },
  previewCell: {
    padding: '6px 6px 6px 0'
  },
  relative: {
    position: 'relative'
  },
  sequestered: {
    cursor: 'not-allowed !important'
  },
  tableRow: {
    '&:hover button': {
      opacity: 1
    },
    cursor: 'pointer',
    transition: theme.transitions.create('backgroundColor', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.sharp
    })
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: (theme.palette.primary.light, 0.85),
      color: theme.palette.primary.main
    }
  },
  tableRowSelected: {
    backgroundColor: (theme.palette.primary.light, 0.85),
    color: theme.palette.primary.main
    // backgroundColor: theme.palette.action.hover
  }
}));

const ArtifactGraphTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [filterAag] = useToggle(false);
  const { connection } = useContext(SettingsContext);
  const [count, setCount] = useState(Infinity);
  const { collectionIds } = useContext(CollectionContext);
  const { tag } = useContext(TagContext);
  const { namespace } = useContext(NamespaceContext);
  const { dataset } = useContext(DatasetContext);
  const { competition } = useContext(CompetitionContext);
  const { token } = useContext(TokenContext.Dynamic);
  const pageSize = props.pageSize || 10;
  const [showCollection, toggleShowCollection] = useToggle(false);
  const { clientId, dataCollectionId, dataCollectionNamespace } = useParams();

  // @todo do in more performance optimized way
  // also look at App.js List while doing this. Pair with @riley?
  let filter = {};

  if (collectionIds && showCollection) {
    filter['metadata.name'] = {
      $in: collectionIds
    };
  }

  if (namespace && trim(namespace) !== '') {
    filter['metadata.namespace'] = {
      $in: [namespace]
    };
  }

  // if (tag && trim(tag) !== '') {
  //   filter['metadata.labels.group'] = {
  //     $in: [tag]
  //   };
  //   delete filter['metadata.namespace'];
  // }

  if (competition && size(competition)) {
    const data_collection = get(competition, 'data_collection', {});
    const { name, namespace } = data_collection;
    filter['$and'] = [
      { 'dataCollectionRefs.namespace': { $eq: namespace } },
      { 'dataCollectionRefs.name': { $eq: name } }
    ];
  }

  if (dataCollectionNamespace && dataCollectionId) {
    filter['$and'] = [
      { 'dataCollectionRefs.namespace': { $eq: dataCollectionNamespace } },
      { 'dataCollectionRefs.name': { $eq: dataCollectionId } }
    ];
  }

  if (dataset) {
    const activeDataset = find(DATASETS, ({ id }) => `${id}` === dataset);
    // TODO: Verify with gym the order of revisions
    const datasetNamespace = get(activeDataset, 'revisions[0].ref.namespace', false);
    if (datasetNamespace) {
      filter['metadata.namespace'] = {
        $in: [datasetNamespace]
      };
    }
  }

  // @todo hook into filter by this
  if (filterAag) {
    filter['metadata.labels.subkind'] = 'AugmentedArtifactGraph';
  }

  // @todo bug in app server?
  // filter['isValid'] = {
  //   $eq: true
  // }

  if (tag && props.useTag && trim(tag) !== '') {
    filter['metadata.labels.group'] = {
      $in: [tag]
    };
  }

  const fetchGraphs = ({ pageParam = undefined }) =>
    fetch(
      `${connection}/artifactgraphs?${
        filter ? `filter=${JSON.stringify(filter)}` : ''
      }&limit=${pageSize}&continueToken=${pageParam ? pageParam : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then((res) => res.text())
      .then(safeParseApiJson);

  const { data, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    ['artifactgraphs', { filter }, token],
    fetchGraphs,
    {
      getNextPageParam: (lastPage) => get(lastPage, 'metadata.continueToken'),
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 20 * 100 * 60
    }
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (isFetching) {
        console.warn('Wait until current request is complete to load next page');
        return;
      }
      if (newPage > page && hasNextPage) {
        fetchNextPage().then(() => {
          setPage(newPage);
        });
      } else {
        setPage(newPage);
      }
    },
    [fetchNextPage, page, hasNextPage, isFetching]
  );

  useEffect(() => {
    if (hasNextPage === false) {
      const totalCount = reduce(data.pages, (acc, page) => acc + size(page.items), 0);
      setCount(totalCount);
    }
  }, [data, hasNextPage]);

  useEffect(() => {
    if (!showCollection) {
      setCount(Infinity);
    } else {
      // go back to page 0
      setPage(0);
    }
  }, [showCollection]);

  useEffect(() => {
    if (!competition || trim(competition) === '') {
      setCount(Infinity);
    } else {
      // go back to page 0
      setPage(0);
    }
  }, [competition]);

  return useMemo(
    () => (
      <>
        {error && 'An error has occurred: ' + error.message}
        {!error && data && data.pages && data.pages.length > 0 && (
          <TableContainer className={classes.container}>
            <Table size="small" aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow className={classes.filterRow}>
                  {clientId === 'eval-3' && (
                    <TableCell colSpan={2}>
                      <CompetitionSelect />
                    </TableCell>
                  )}
                </TableRow>
                {/* <TableRow className={classes.filterRow}>
                  <TableCell colSpan={2}>
                    <TagPanel />
                  </TableCell>
                </TableRow>
                <TableRow className={classes.filterRow}>
                  <TableCell colSpan={2}>
                    <NamespaceFilter />
                  </TableCell>
                </TableRow>
                <TableRow className={classes.filterRow}>
                  <TableCell colSpan={2}>
                    <DatasetSelect />
                  </TableCell>
                </TableRow> */}
                <TableRow className={classes.filterRow}>
                  <TableCell component="th" className={classes.buttonCell} colSpan={1}>
                    <IconButton
                      size="small"
                      className={classes.filterButton}
                      onClick={() => toggleShowCollection()}
                    >
                      {showCollection ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>
                    <IconButton size="small" disabled className={classes.filterButton}>
                      <SortIcon />
                    </IconButton>
                    {/* <IconButton
                      size="small"
                      className={classes.filterButton}
                      onClick={() => toggleFilterAag()}
                    >
                      {filterAag ? <BlurOnIcon /> : <BlurOffIcon />}
                    </IconButton> */}
                  </TableCell>
                  <TableCell className={classes.buttonCell} colSpan={2}>
                    <TablePagination
                      component="div"
                      backIconButtonProps={{
                        size: 'small'
                      }}
                      nextIconButtonProps={{
                        size: 'small'
                      }}
                      rowsPerPageOptions={[pageSize]}
                      count={count} // @todo get from api once added
                      rowsPerPage={pageSize}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {get(data, ['pages', page, 'items'], []).map((row) => (
                  <Row
                    whichRoute={props.whichRoute}
                    row={row}
                    // sequestered={get(row, 'metadata.labels.sequestered', true)}
                    key={`${get(row, 'metadata.namespace')}-${get(row, 'metadata.name')}`}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [
      error,
      data,
      classes.container,
      classes.filterRow,
      classes.buttonCell,
      classes.filterButton,
      clientId,
      showCollection,
      pageSize,
      count,
      page,
      handleChangePage,
      toggleShowCollection,
      props.whichRoute
    ]
  );
};

function Row(props) {
  const classes = useStyles();
  const { row, sequestered, whichRoute } = props;
  const history = useHistory();
  const location = useLocation();
  const { clientId, dataCollectionId, dataCollectionNamespace } = useParams();
  const { resetCollection } = useContext(AgCollectionContext);
  const isSelected = useMemo(() => {
    return location.pathname.includes(
      `/${row.metadata.namespace}/${row.metadata.name}`
    );
  }, [location.pathname, row.metadata.name, row.metadata.namespace]);
  const handleRowClick = useCallback(
    (agNamespace, agId, sequestered) => {
      if (sequestered !== true) {
        resetCollection(agId);
        // @todo
        if (dataCollectionId && dataCollectionNamespace) {
          history.push(`/${clientId}/datacollections/namespace/${dataCollectionNamespace}/name/${dataCollectionId}/${agNamespace}/${agId}`);
        } else {
          history.push(`/${clientId}/${whichRoute}/${agNamespace}/${agId}`);
        }
      }
      
    },
    [clientId, dataCollectionId, dataCollectionNamespace, history, resetCollection, whichRoute]
  );
  return useMemo(
    () => (
      <TableRow
        className={clsx(classes.tableRow, {
          [classes.tableRowSelected]: isSelected && !sequestered,
          [classes.sequestered]: sequestered,
          [classes.tableRowHover]: !sequestered
        })}
        onClick={() => handleRowClick(row.metadata.namespace, row.metadata.name, sequestered)}
      >
        <TableCell className={classes.buttonCell} align="center">
          <LoadEgsForAg
            ag={row}
            agId={get(row, 'metadata.name')}
            agNamespace={get(row, 'metadata.namespace')}
            renderComponent={ScoreViewerSimpleDots}
          />
          <div className={classes.button}>
            <ToggleCollection id={get(row, 'metadata.name')} />
          </div>
        </TableCell>
        <TableCell className={classes.previewCell}>
          <LoadAOMForAg
            sequestered={sequestered}
            ag={row}
            renderComponent={AOMContentSmartPreview}
          />
        </TableCell>
      </TableRow>
    ),
    [
      classes.button,
      classes.buttonCell,
      classes.previewCell,
      classes.sequestered,
      classes.tableRow,
      classes.tableRowHover,
      classes.tableRowSelected,
      handleRowClick,
      isSelected,
      row,
      sequestered
    ]
  );
}

export default ArtifactGraphTable;
