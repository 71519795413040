/* eslint-disable sort-keys-fix/sort-keys-fix */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import makeStyles from '@mui/styles/makeStyles';
import Cytoscape from 'cytoscape';
import { chain, get, reduce, size } from 'lodash';
import React, { useMemo, useState } from 'react';

import checkNodeIdsForEdgesExist from '../../helpers/checkNodeIdsForEdgesExist';
import formatEdgesForGraph from '../../helpers/formatEdgesForGraph';
import formatNodesForGraph from '../../helpers/formatNodesForGraph';
import LLRGauge from '../LLRGauge';
import nodeType from '../NodeType';
import ToggleAnalytic from '../ToggleAnalytic';

// @todo display image / text inconsistency
// aomLoc is json stringified start and stop
// ex: http://localhost:3001/gallery/a5b608dce8683924c8bd1e6f7ccf1d0cd16f65fd04b93ac96ca480a3097d2fb9
//

function round(num) {
  if (num !== undefined && num !== null && num.toFixed) {
    return num.toFixed(2);
  }
}

function formatFromCyNode(cyNode) {
  let label = '';
  const originalNode = cyNode.data('originalNode');
  if (!nodeType[originalNode.nodeType]) {
    console.warn(`Missing nodeType formatter for ${originalNode.nodeType}`);
  } else {
    label = nodeType[originalNode.nodeType](originalNode);
  }
  return label;
}

const ScoreViewer = (props) => {
  const [sortBy, setSortBy] = useState('EvDetectionNode');
  const [sortDir, setSortDir] = useState('desc');
  const { nodes = [], edges = [], showAg = true, associatedGraphs } = props;

  // @TODO DRY ======== begin stuff to DRY into helpers

  // @TODO check for valid data
  const hasAnyData = size(nodes) > 0 || size(edges) > 0;

  const fakeNodes = [];
  const fakeEdges = [];

  // add fake nodes for associated Graphs
  if (showAg && associatedGraphs) {
    nodes.forEach(({ graphId, id: _id, nodeId, nodeType }) => {
      if (nodeType === 'EvReferenceNode') {
        const targetName = get(associatedGraphs, [graphId, 'name'], null);
        if (!targetName) {
          // @todo graph is invalid
          // isValid.valid = false;
          // isValid.errors.push(`associatedGraphs.${graphId} does not exist.`);
        } else {
          // @todo here!
          // @todo remove this check
          if (nodeId === '2984603c-0437-4250-be76-b8b3d917ecd0') {
            nodeId = '0';
          }
          if (nodeId !== undefined) {
            const newEdge = {
              edgeType: '',
              source: _id,
              target: `${targetName}-${nodeId}`
            };
            fakeEdges.push(newEdge);
          } else {
            const newEdge = {
              // @todo replace with actual rootNodeId
              edgeType: '',

              source: _id,
              target: `${targetName}-${0}`
            };
            fakeEdges.push(newEdge);
          }
        }
      }
    });
  }

  // * add originalNode / link
  // * add some basic style props
  let _nodes = formatNodesForGraph([...nodes, ...fakeNodes]);
  let _edges = formatEdgesForGraph([...edges, ...fakeEdges]);

  // @todo optimize this, move outta here, into helper maybe? Use reduce maybe?
  const els = useMemo(() => {
    let ret = [];
    _nodes.forEach((node) => {
      ret.push({
        data: {
          ...node
        }
      });
    });
    _edges.forEach((edge) => {
      ret.push({
        data: {
          ...edge
        }
      });
    });
    return ret;
  }, [_nodes, _edges]);

  const isValid = checkNodeIdsForEdgesExist(_nodes, _edges);

  // ======== END @TODO DRY

  return useMemo(() => {
    if (!hasAnyData) return 'No graph data.';
    if (isValid.valid !== true) {
      return (
        <>
          <span>Invalid graph data:&nbsp;</span>
          <ul>
            {isValid.errors.map((error, idx) => (
              <li key={idx}>{error}&nbsp;</li>
            ))}
          </ul>
        </>
      );
    }

    const cy = new Cytoscape({
      elements: els,
      headless: true,
      styleEnabled: false
    });

    // + is ele1 after ele2
    const analytics = cy.nodes('[nodeType = "EvAnalysisNode"]').sort((ele1, ele2) => {
      const ele1Score = ele1.outgoers(`node[nodeType = "${sortBy}"]`);
      const ele2Score = ele2.outgoers(`node[nodeType = "${sortBy}"]`);
      // neither score
      if (!ele1Score[0] && !ele2Score[0]) {
        return -1;
      }
      if (!ele1Score[0]) {
        return 1;
      }
      if (!ele2Score[0]) {
        return -1;
      }
      const ele1LLR = ele1Score[0].data('originalNode').score.score;
      const ele2LLR = ele2Score[0].data('originalNode').score.score;
      if (ele1LLR === ele2LLR) {
        return 0;
      }
      if (ele1LLR > ele2LLR) {
        return sortDir === 'desc' ? -1 : 1;
      }
      return sortDir === 'desc' ? 1 : -1;
    });

    return (
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Analytic Name</TableCell>
              <TableCell>version</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === 'EvDetectionNode'}
                  direction={sortDir}
                  onClick={() => {
                    const isDesc = sortBy === 'EvDetectionNode' && sortDir === 'desc';
                    setSortDir(isDesc ? 'asc' : 'desc');
                    setSortBy('EvDetectionNode');
                  }}
                >
                  Detection
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === 'EvAttributionNode'}
                  direction={sortDir}
                  onClick={() => {
                    const isDesc = sortBy === 'EvAttributionNode' && sortDir === 'desc';
                    setSortDir(isDesc ? 'asc' : 'desc');
                    setSortBy('EvAttributionNode');
                  }}
                >
                  Attribution
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy === 'EvCharacterizationNode'}
                  direction={sortDir}
                  onClick={() => {
                    const isDesc = sortBy === 'EvCharacterizationNode' && sortDir === 'desc';
                    setSortDir(isDesc ? 'asc' : 'desc');
                    setSortBy('EvCharacterizationNode');
                  }}
                >
                  Characterization
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>Fused D</TableCell>
              <TableCell>Fused A</TableCell>
              <TableCell>Fused C</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analytics.map((a) => (
              <AnalyticRow a={a} key={a.data('id')} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [els, hasAnyData, isValid.errors, isValid.valid, sortBy, sortDir]);
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

function AnalyticRow(props) {
  const { a } = props;
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const dScore = a.outgoers('node[nodeType = "EvDetectionNode"]');
  const aScore = a.outgoers('node[nodeType = "EvAttributionNode"]');
  const cScore = a.outgoers('node[nodeType = "EvCharacterizationNode"]');
  const scores = a.outgoers(`
    node[nodeType = "EvAttributionNode"],
    node[nodeType = "EvDetectionNode"],
    node[nodeType = "EvCharacterizationNode"]
  `);
  const scale = {
    red: {
      high: Infinity,
      low: 0.5000000000000000001
    },
    orange: {
      high: 0.5,
      low: 0.2000000000000000001
    },
    white: {
      high: 0.2,
      low: -0.5
    },
    green: {
      high: -0.5000000000000000001,
      low: -Infinity
    }
  };

  const styles = {
    red: {
      backgroundColor: '#ef5350',
      color: 'white',
      fontWeight: 'bold'
    },
    orange: {
      backgroundColor: '#ffc107',
      color: 'white',
      fontWeight: 'bold'
    },
    white: {
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: 'bold'
    },
    green: {
      backgroundColor: '#4caf50',
      color: 'white',
      fontWeight: 'bold'
    }
  };

  function getTextFromScore(score) {
    return chain(scale).pickBy(textHasScoreRange).keys().value();

    function textHasScoreRange(rangeObj) {
      return rangeObj.low <= score && rangeObj.high >= score;
    }
  }
  const ignores = a.outgoers(`node[nodeType = "EvIgnoredAssetNode"]`);
  const dScoreColor = getTextFromScore(
    dScore.length > 0 && round(dScore[0].data('originalNode').score.score)
  );
  const aScoreColor = getTextFromScore(
    aScore.length > 0 && round(aScore[0].data('originalNode').score.score)
  );
  const cScoreColor = getTextFromScore(
    cScore.length > 0 && round(cScore[0].data('originalNode').score.score)
  );
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <ToggleAnalytic id={a.data('originalNode').analytic} />
        </TableCell>
        <TableCell>{a.data('originalNode').analytic}</TableCell>
        <TableCell>{a.data('originalNode').version}</TableCell>
        <TableCell align="right" style={{ ...styles[dScoreColor] }}>
          {dScore.length > 0 && round(dScore[0].data('originalNode').score.score)}
          <span> </span>
          {dScore.length > 0 && get(dScore[0].data('originalNode'), 'score.scoreType}', '')}
        </TableCell>
        <TableCell align="right" style={{ ...styles[aScoreColor] }}>
          {aScore.length > 0 && round(aScore[0].data('originalNode').score.score)}
          <span> </span>
          {aScore.length > 0 && get(aScore[0].data('originalNode'), 'score.scoreType}', '')}
        </TableCell>
        <TableCell align="right" style={{ ...styles[cScoreColor] }}>
          {cScore.length > 0 && round(cScore[0].data('originalNode').score.score)}
          <span> </span>
          {cScore.length > 0 && get(cScore[0].data('originalNode'), 'score.scoreType}', '')}
        </TableCell>
        {/* <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell> */}
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {/* {scores && (
                <>
                  <h3>Hypothesis Results</h3>
                  {scores.map((c) => (
                    <HypothesisSummary score={c} key={c.data('id')} />
                  ))}
                </>
              )} */}
              {scores && (
                <>
                  <h3>Scores</h3>
                  <ul>
                    {scores.map((c) => {
                      const checks = c.successors(`node[nodeType = "EvConsistencyCheckNode"]`);
                      return (
                        <li key={c.data('id') || c}>
                          {formatFromCyNode(c)}
                          <br />
                          {/* <LLRGauge node={c.data('originalNode')} /> */}
                          <br />
                          <Checks checks={checks} />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {ignores && (
                <>
                  <h3>Ignored/ Opt-Out</h3>
                  <ul>
                    {ignores.map((i) => {
                      return <li key={i.data('id')}>{formatFromCyNode(i)}</li>;
                    })}
                  </ul>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const types = {
  EvAttributionNode: 'Attribution',
  EvCharacterizationNode: 'Characterization',
  EvDetectionNode: 'Detection'
};

// eslint-disable-next-line no-unused-vars
function HypothesisSummary({ score }) {
  const checks = score.successors(`node[nodeType = "EvConsistencyCheckNode"]`);
  const { nodeType } = score.data('originalNode');
  const displayType = types[nodeType];
  return (
    <Box
      p={3}
      bgcolor="background.default"
      borderColor="action.disabled"
      borderRadius="3px"
      border="1px solid"
      marginBottom={3}
    >
      <h4 style={{ margin: 0 }}>{displayType}</h4>
      <ul>
        {checks.map((check) => {
          const evidenceNodes = check.successors(`node[nodeType = "EvConceptNode"]`);
          // this MMA has a ${type} inconsistency evidenced by ${evidence}
          const evidence = reduce(
            evidenceNodes,
            (acc, e) => {
              return [...acc, e.data('originalNode').label];
            },
            []
          );
          const { category, score } = check.data('originalNode');
          const statement = `"this MMA has a ${category.toLowerCase()} ${score.scoreType} ${
            score.score
          } inconsistency evidenced by ${evidence.join(', ')}"`;
          return (
            <li key={check.data('id') || check}>
              <strong>
                <LLRGauge node={check.data('originalNode')} />
              </strong>{' '}
              {statement}
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

function Checks(props) {
  const { checks } = props;
  return (
    <React.Fragment>
      <span>Evidenced by Consistency Checks:</span>
      <br />
      <ul>
        {checks.map((check) => {
          const evidence = check.successors(`node[nodeType = "EvConceptNode"]`);
          return (
            <li key={check.data('id') || check}>
              {formatFromCyNode(check)}
              {/* <LLRGauge node={check.data('originalNode')} /> */}
              <br />
              <span>Evidence Concepts:</span>
              <br />
              <ul>
                {evidence.map((e) => {
                  const aomRefsLocation = e
                    .connectedEdges('edge[edgeType = "LocationInAsset"]')
                    .connectedNodes('node[nodeType = "EvAomLocIdNode"]');
                  const aomRefsNoLocation = e
                    .connectedEdges('edge[edgeType = "InAsset"]')
                    .connectedNodes('node[nodeType = "EvReferenceNode"]');
                  return (
                    <li key={e.data('id') || e}>
                      {formatFromCyNode(e)}
                      <LLRGauge node={e.data('originalNode')} />
                      {}
                      {aomRefsNoLocation.length && (
                        <>
                          <br />
                          <span>EvReferenceNodes (No location specified)</span>
                          <br />
                        </>
                      )}
                      <ul>
                        {/* {aomRefsNoLocation.map((refNode) => {
                          // should only be one
                          // @todo could also give our "FAKE" edge a better name and we could use to get this
                          const theImg = refNode.outgoers('node');
                          let uri = theImg.data('originalNode').assetDataUri || '';
                          let ret = 'uri missing';
                          if (uri) {
                            uri = replaceMinioEndpoint(uri);
                            const type = mimeTypes.lookup(uri);
                            if (type.indexOf('image/') === 0) {
                              ret = <img width="200" alt="article" src={uri} />;
                            } else if (type.indexOf('application/json') === 0) {
                              ret = (
                                <a href={uri} target="_blank" rel="noreferrer">
                                  <DescriptionIcon /> Entire AOM
                                </a>
                              );
                            } else {
                              ret = (
                                <p style={{ color: 'red' }}>Reference {type} not yet supported</p>
                              );
                            }
                          }
                          return (
                            <React.Fragment key={refNode.data('id') || uri}>{ret}</React.Fragment>
                          );
                        })} */}
                      </ul>
                      {aomRefsLocation.length > 0 && (
                        <>
                          <br />
                          <span>EvReferenceNodes (Specific location)</span>
                          <br />
                        </>
                      )}
                      <ul>
                        {aomRefsLocation.map((refNode) => {
                          let loc;
                          const { aomLoc } = refNode.data('originalNode');
                          // it might be json
                          try {
                            loc = JSON.parse(aomLoc);
                          } catch (e) {
                            loc = aomLoc;
                          }
                          return <pre key={refNode.data('id')}>{JSON.stringify(loc, null, 2)}</pre>;
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
}

export default ScoreViewer;
