import { gql, NetworkStatus, useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import some from 'lodash/some';
import React, { useEffect } from 'react';

import ManualQuery from './../ManualQuery';

const QUERY = gql`
  query ($name: String!, $namespace: String!) {
    artifactGraphs(where: { namespace: $namespace, name: $name }, limit: 1) {
      data {
        workflows {
          name
          analytic_name
          namespace
          status
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  headerRow: {
    '& > *': {
      borderBottomColor: 'rgba(255, 255, 255, 0.2)',
      fontWeight: 'bold'
    }
  },
  high_negative: {
    color: '#4caf50'
  },
  high_positive: {
    color: '#ef5350'
  },
  neutral: {
    color: 'rgba(255, 255, 255, 0.2)'
  },
  questionable: {
    color: '#ffc107'
  },
  row: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const WorkflowsTable = ({ name, namespace }) => {
  const classes = useStyles();
  const { data, error, loading, networkStatus, refetch, stopPolling } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000,
    variables: {
      name,
      namespace
    }
    // fetchPolicy: "no-cache",
  });

  const workflows = get(data, 'artifactGraphs.data[0].workflows', []);

  useEffect(() => {
    if (workflows.length > 0) {
      if (!some(workflows, (workflow) => workflow.status === 'RUNNING')) {
        stopPolling();
      }
    }
  }, [stopPolling, workflows]);

  const networkActive =
    networkStatus === NetworkStatus.poll || networkStatus === NetworkStatus.refetch;

  const showLoading = loading && !networkActive;

  const showTable = !loading || networkActive;

  return (
    <>
      {error && <pre>Error: {JSON.stringify(error, null, 2)}</pre>}
      {showLoading && <p>loading...</p>}
      {showTable && (
        <Paper>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflows.map((workflow) => (
                  <TableRow className={classes.row} key={workflow.name}>
                    <TableCell>{workflow.analytic_name}</TableCell>
                    <TableCell>{workflow.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      <ManualQuery refetch={refetch} networkStatus={networkStatus} disabled={showLoading} />
    </>
  );
};
export default WorkflowsTable;
