import { useCallback, useContext } from "react";

import { SettingsContext } from "../contexts/SettingsContext";
import { TokenContext } from "../contexts/TokenContext";

/* eslint-disable sort-keys-fix/sort-keys-fix */
export default function useMinioToAppServer() {
  const { connection } = useContext(SettingsContext);
  const  { token } = useContext(TokenContext.Dynamic);
  
  return useCallback((url) => {
    const replaced = url.replace('http://semafor-minio:9000/', '');
    // /XXX/YYY/AAA/BBB/CCC
    const split = replaced.split('/');
    const namespace = split.shift();
    const datastash = split.shift();
    const path = split.join('/');
    let uri = `${connection}/namespaces/${namespace}/datastashes/${datastash}/binary?path=${path}`
    if (token) {
      uri = `${uri}&auth=${token}`;
    }
    return uri;
  }, [connection, token]);
}
