import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import CssBaseline from '@mui/material/CssBaseline';
import { adaptV4Theme } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MainContent from './components/MainContent';
import { AgCollectionProvider } from './contexts/AgCollectionContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { CollectionProvider } from './contexts/CollectionContext';
import { CompetitionContextProvider } from './contexts/CompetitionContext';
// import { CompetitionContextProvider } from "./contexts/CompetitionContext";
import { DatasetContextProvider } from './contexts/DatasetContext';
import { NamespaceContextProvider } from './contexts/NamespaceContext';
import { NavDrawerContextProvider } from './contexts/NavDrawerContext';
import { RolesProvider } from './contexts/RolesContext';
import { TagContextProvider } from './contexts/TagContext';
import { TokenProvider } from './contexts/TokenContext';
import { UsernameProvider } from './contexts/UsernameContext';
import useGraphqlConfig from './hooks/useGraphqlConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  }
}));

function App() {
  const classes = useStyles();

  const theme = React.useMemo(
    () =>
      createTheme(adaptV4Theme({
        palette: {
          // @todo hook into user preference
          type: 'light'
        }
      })),
    []
  );

  const { clientId } = useParams();

  const initOptions = useMemo(
    () => ({
      onLoad: 'login-required'
    }),
    []
  );

  const onEvent = useCallback((event, error) => {
    if (event === 'onInitError') {
      console.log('init error keycloak');
    }
  }, []);

  const keycloakConfig = useMemo(
    () => ({
      clientId: `semafor.${clientId}`,
      realm: 'semafor',
      url: 'https://keycloak.semaforprogram.com/auth/'
    }),
    [clientId]
  );

  const keycloak = useMemo(() => Keycloak(keycloakConfig), [keycloakConfig]);

  const uri = useGraphqlConfig();
  const cache = useMemo(() => new InMemoryCache(), []);
  const httpLink = useMemo(() => createHttpLink({ uri }), [uri]);

  /**
   * @todo consider getting from local state,putting clientId AND token into localstorage
   * or similar. Can apollo wrapper be in index.js ?
   */
  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem(`${clientId}.token`);
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            clientId: clientId ? clientId : ''
          }
        };
      }),
    [clientId]
  );

  const link = useMemo(
    () => authLink.concat(httpLink),
    [authLink, httpLink]
  );


  const client = useMemo(
    () =>
      new ApolloClient({
        cache,
        link
      }),
    [cache, link]
  );

  return useMemo(
    () => (
      <ApolloProvider client={client}>
        <ReactKeycloakProvider onEvent={onEvent} authClient={keycloak} initOptions={initOptions}>
          <TokenProvider>
            <RolesProvider>
              <NavDrawerContextProvider>
                {/* <StyledEngineProvider injectFirst> */}
                  <ThemeProvider theme={theme}>
                    <DatasetContextProvider>
                      <CompetitionContextProvider>
                        <CollectionProvider>
                          <NamespaceContextProvider>
                            <TagContextProvider>
                              <AnalyticsProvider>
                                <AgCollectionProvider>
                                  <UsernameProvider>
                                    <div className={classes.root}>
                                      <CssBaseline />
                                      <MainContent />
                                    </div>
                                  </UsernameProvider>
                                </AgCollectionProvider>
                              </AnalyticsProvider>
                            </TagContextProvider>
                          </NamespaceContextProvider>
                        </CollectionProvider>
                      </CompetitionContextProvider>
                    </DatasetContextProvider>
                  </ThemeProvider>
                {/* </StyledEngineProvider> */}
              </NavDrawerContextProvider>
            </RolesProvider>
          </TokenProvider>
        </ReactKeycloakProvider>
      </ApolloProvider>
    ),
    [classes.root, client, initOptions, keycloak, onEvent, theme]
  );
}

export default App;
