const Property = (props = {}) => {
  let label = `Score Type: ${props.scoreType}, Score Value: ${props.score}`;
  if (props.hypothesis) {
    label += `\nhypothesis: ${props.hypothesis}`;
  }
  if (!props.hypothesis) {
    label += `\nhypothesis: None provided`;
  }
  if (props.logLikelihoodRatio) {
    label += `\nLLR: ${props.logLikelihoodRatio}`;
  }
  if (props.lowerLogLikelihoodRatio) {
    label += `\nLower LLR: ${props.lowerLogLikelihoodRatio}`;
  }
  if (props.upperLogLikelihoodRatio) {
    label += `\nUpper LLR: ${props.upperLogLikelihoodRatio}`;
  }
  if (props.priorsLogLikelihoodRatio) {
    label += `\nPrior LLR: ${props.priorsLogLikelihoodRatio}`;
  }
  if (props.probEvidence) {
    label += `\nProb Evidence: ${props.probEvidence}`;
  }
  if (props.probManipulation) {
    label += `\nProb Manipulation: ${props.probManipulation}`;
  }
  return label;

  // hypothesis
  // logLikelihoodRatio
  // lowerLogLikelihoodRatio
  // upperLogLikelihoodRatio
  // priorsLogLikelihoodRatio
  // probEvidence
  // probManipulation
};

export default Property;
