import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle as MuiDialogTitle, IconButton, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect, useState } from 'react';

const uiStyles = (theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
});

const DialogTitle = withStyles(uiStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const NodeDetailViewer = (props) => {
  const { selectedNode } = props;
  const [open, setOpen] = useState(selectedNode ? true : false);
  const handleClose = (event) => {
    setOpen(false);
  };
  useEffect(() => {
    if (selectedNode) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedNode, setOpen]);
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Node Detail
      </DialogTitle>
      {selectedNode && <pre style={{ margin: 20 }}>{JSON.stringify(selectedNode, null, 2)}</pre>}
    </Dialog>
  );
};

export default NodeDetailViewer;
