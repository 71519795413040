import { gql, useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { find } from 'lodash';
import React, { useState } from 'react';

import { useLocalizationStore } from './localizationStore';

const QUERY = gql`
  query EvidenceGraph($offset: Int, $limit: Int, $ag_name: String, $ag_namespace: String) {
    evidenceGraphs(offset: $offset, limit: $limit, ag_name: $ag_name, ag_namespace: $ag_namespace) {
      data {
        id
        ags {
          name
          namespace
        }
        name
        namespace
        workflow_ref
        component_ref
        localization_nodes {
          videoRegionLoc {
            startTime
            endTime
          }
          imageLocBoundingPoly {
            hole
            points {
              x
              y
            }
          }
          aomLoc {
            start {
              index
              uuid
            }
            stop {
              index
              uuid
            }
          }
        }
      }
    }
  }
`;

const LocalizationTable = ({ name, namespace }) => {
  const [activeRow, setActiveRow] = useState(null);
  const {
    resetLocalizationStore,
    setEndTime,
    setHole,
    setLocalizationType,
    setPoints,
    setStartIndex,
    setStartTime,
    setStartUUID,
    setStopIndex,
    setStopUUID,
  } = useLocalizationStore();

  const { data, loading } = useQuery(QUERY, {
    variables: {
      ag_name: name,
      ag_namespace: namespace
    }
  });


  if (loading || data == undefined || data.evidenceGraphs == undefined) {
    return <h4>Loading...</h4>;
  }

  if (!loading && data.evidenceGraphs.data.length === 0) {
    return <h4>No Localizations Available</h4>;
  }

  const findLocType = (eg) => {
    if (eg.localization_nodes[0].aomLoc !== null) {
      return 'aomLoc';
    }
    if (eg.localization_nodes[0].videoRegionLoc !== null) {
      return 'videoRegionLoc';
    }
    if (eg.localization_nodes[0].imageLocBoundingPoly !== null) {
      return 'imageLocBoundingPoly';
    }
    return null;
  };

  console.log("Yeah")

  const AomLocTable = ({ data, loading }) => {
    if(!loading && !find(data.evidenceGraphs.data, (eg) => findLocType(eg) === "aomLoc")){
      return null
    }
    return(
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>EG</TableCell>
              <TableCell>Analytic</TableCell>
              <TableCell>Workflow</TableCell>
              <TableCell>Start UUID</TableCell>
              <TableCell>Start Index</TableCell>
              <TableCell>Stop UUID</TableCell>
              <TableCell>Stop Index</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {data &&
              data.evidenceGraphs.data.map((eg) => {
                if (findLocType(eg) === 'aomLoc') {
                  return <AomLocTableRow eg={eg} />;
                }
                return null;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
};

const ImageLocTable = ({ data, loading }) => {
  if(!loading && !find(data.evidenceGraphs.data, (eg) => findLocType(eg) === "imageLocBoundingPoly")){
    return null
  }
  return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>EG</TableCell>
            <TableCell>Analytic</TableCell>
            <TableCell>Workflow</TableCell>
            <TableCell>Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {data &&
            data.evidenceGraphs.data.map((eg) => {
              if (findLocType(eg) === 'imageLocBoundingPoly') {
                return <ImageLocTableRow eg={eg} />;
              }
              return null;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

  const VideoRegionLocTable = ({ data, loading }) => {
    if(!loading && !find(data.evidenceGraphs.data, (eg) => findLocType(eg) === "videoRegionLoc")){
      return null
    }
    return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>EG</TableCell>
            <TableCell>Analytic</TableCell>
            <TableCell>Workflow</TableCell>
            <TableCell>Localization Start Time (seconds)</TableCell>
            <TableCell>Localization End Time (seconds)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          )}
          {data &&
            data.evidenceGraphs.data.map((eg) => {
              if (findLocType(eg) === 'videoRegionLoc') {
                return <VideoRegionLocTableRow eg={eg} />;
              }
              return null;
            })}
        </TableBody>
      </Table>
    </TableContainer>
    )
  };

  const ImageLocTableRow = ({ eg }) => (
    <TableRow
      sx={{
        '&:hover': {
          border: '2px solid #025ab3cc'
        },
        backgroundColor: activeRow === eg.id ? 'rgba(80, 120, 160, 0.411)' : null,
        border: activeRow === eg.id ? '2px solid #025ab3cc' : null
      }}
      key={eg.id}
      onClick={() => {
        if (activeRow === eg.id) {
          resetLocalizationStore();
          setActiveRow('');
        } else {
          setActiveRow(eg.id);
          setLocalizationType('imageLocBoundingPoly');
          setHole(eg.localization_nodes[0].imageLocBoundingPoly.hole);
          setPoints(eg.localization_nodes[0].imageLocBoundingPoly.points);
        }
      }}
    >
      <TableCell>{eg.name}</TableCell>
      <TableCell>{eg.component_ref}</TableCell>
      <TableCell>{eg.workflow_ref}</TableCell>
      <TableCell>Click to view localization polygon</TableCell>
    </TableRow>
  );

  const VideoRegionLocTableRow = ({ eg }) => (
    <TableRow
      sx={{
        '&:hover': {
          border: '2px solid #025ab3cc'
        },
        backgroundColor: activeRow === eg.id ? 'rgba(80, 120, 160, 0.411)' : null,
        border: activeRow === eg.id ? '2px solid #025ab3cc' : null
      }}
      key={eg.id}
      onClick={() => {
        if (activeRow === eg.id) {
          resetLocalizationStore();
          setActiveRow('');
        } else {
          setActiveRow(eg.id);
          setLocalizationType('VideoRegionLoc');
          setStartTime(eg.localization_nodes[0].videoRegionLoc.startTime);
          setEndTime(eg.localization_nodes[0].videoRegionLoc.endTime);
        }
      }}
    >
      <TableCell>{eg.name}</TableCell>
      <TableCell>{eg.component_ref}</TableCell>
      <TableCell>{eg.workflow_ref}</TableCell>
      <TableCell>{eg.localization_nodes[0].videoRegionLoc.startTime}</TableCell>
      <TableCell>{eg.localization_nodes[0].videoRegionLoc.endTime}</TableCell>
    </TableRow>
  );

  const AomLocTableRow = ({ eg }) => (
    <TableRow
      sx={{
        '&:hover': {
          border: '2px solid #025ab3cc'
        },
        backgroundColor: activeRow === eg.id ? 'rgba(80, 120, 160, 0.411)' : null,
        border: activeRow === eg.id ? '2px solid #025ab3cc' : null
      }}
      key={eg.id}
      onClick={() => {
        if (activeRow === eg.id) {
          resetLocalizationStore();
          setActiveRow('');
        } else {
          setActiveRow(eg.id);
          setLocalizationType('AOMLoc');
          setStartIndex(eg.localization_nodes[0].aomLoc.start.index);
          setStartUUID(eg.localization_nodes[0].aomLoc.start.uuid);
          setStopUUID(eg.localization_nodes[0].aomLoc.stop.uuid);
          setStopIndex(eg.localization_nodes[0].aomLoc.stop.index);
        }
      }}
    >
      <TableCell>{eg.name}</TableCell>
      <TableCell>{eg.component_ref}</TableCell>
      <TableCell>{eg.workflow_ref}</TableCell>
      <TableCell>{eg.localization_nodes[0].aomLoc.start.uuid}</TableCell>
      <TableCell>{eg.localization_nodes[0].aomLoc.start.index}</TableCell>
      <TableCell>{eg.localization_nodes[0].aomLoc.stop.uuid}</TableCell>
      <TableCell>{eg.localization_nodes[0].aomLoc.stop.index}</TableCell>
    </TableRow>
  );

  return (
    <>
      <AomLocTable data={data} loading={loading} />
      <VideoRegionLocTable data={data} loading={loading} />
      <ImageLocTable data={data} loading={loading} />
    </>
  );
};

export default LocalizationTable;
