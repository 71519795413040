import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material';
import Cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import { get, size } from 'lodash';
import mimeTypes from 'mime-types';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import { useMeasure } from 'react-use';

// import COSEBilkent from 'cytoscape-cose-bilkent';
import nodeColors from '../../constants/nodeColors';
import { TokenContext } from '../../contexts/TokenContext';
import checkNodeIdsForEdgesExist from '../../helpers/checkNodeIdsForEdgesExist';
import formatEdgesForGraph from '../../helpers/formatEdgesForGraph';
// import cola from 'cytoscape-cola';
// import fcose from 'cytoscape-fcose';
import formatNodesForGraph from '../../helpers/formatNodesForGraph';
import useMinioToAppServer from '../../helpers/useMinioToAppServer';
import nodeType from '../NodeType';
import styles from './GraphViewCtyo.module.css';

// Cytoscape.use(COSEBilkent);
Cytoscape.use(dagre);
// Cytoscape.use(cola);
// Cytoscape.use(fcose);

const GraphViewCtyo = (props) => {
  const urlMaker = useMinioToAppServer();
  const theme = useTheme();
  const { token } = useContext(TokenContext.Dynamic);
  const { ag } = props;
  // const rootNodeId = get(props, 'ag.rootNodeId', '0');
  const [ref, { height, width }] = useMeasure();
  const layout = useMemo(
    () =>
      props.layout || {
        name: 'dagre',
        nodeDimensionsIncludeLabels: true
      },
    [props.layout]
  );

  const isValidAg = get(ag, 'isValid', undefined);
  const validationError = get(ag, 'validationError', '');

  const cyRef = useRef(null);

  const {  edges = [], full, id, nodes = [],  } = props;

  // @TODO check for valid data
  const hasAnyData = size(nodes) > 0 || size(edges) > 0;

  const fakeNodes = [];
  const fakeEdges = [];

  // debugger;

  // add fake nodes for associated Graphs
  // if (showAg && associatedGraphs) {
  //   nodes.forEach(({ id: _id, nodeType, referencedGraphId, referencedNodeId, ...props }) => {
  //     if (nodeType === 'AgReferenceNode') {
  //       // console.log('we have an agReference node', referencedNodeId);
  //       const found = Object.values(associatedGraphs).find(
  //         (g) => g.localName === referencedGraphId
  //       );
  //       let targetName;
  //       if (found && found.graphReference) {
  //         targetName = found.graphReference.name;
  //       }
  //       // if (targetName === 'purdue-unina-image-manipulation-detection-0-1-3-aag-62ff8a78-f856-45ef-9139-a9cbbac1681d') {
  //       //   debugger;
  //       // }
  //       if (targetName) {
  //         if (referencedNodeId !== undefined) {
  //           const newEdge = {
  //             edgeType: '',
  //             source: _id,
  //             target: `${targetName}-${referencedNodeId}`
  //           };
  //           fakeEdges.push(newEdge);
  //         } else {
  //           let target = `${targetName}-${rootNodeId}`;
  //           let foundTarget = find(nodes, ({ id }) => id === target);
  //           if (!foundTarget) {
  //             target = `${targetName}-${rootNodeId === '0' ? 'root' : '0'}`;
  //             foundTarget = find(nodes, ({ id }) => id === target);
  //           }
  //           const newEdge = {
  //             // @todo replace with actual rootNodeId
  //             edgeType: '',
  //             source: _id,
  //             target
  //           };
  //           fakeEdges.push(newEdge);
  //         }
  //       }
  //     }

  //     if (nodeType === 'EvReferenceNode') {
  //       const found = Object.values(associatedGraphs).find(
  //         (g) => g.localName === referencedGraphId
  //       );
  //       let targetName;
  //       if (found.graphReference) {
  //         targetName = found.graphReference.name;
  //       }

  //       // if (targetName === 'purdue-unina-image-manipulation-detection-0-1-3-aag-62ff8a78-f856-45ef-9139-a9cbbac1681d') {
  //       //   debugger;
  //       // }

  //       // const targetName = get(associatedGraphs, [referencedGraphId, 'name'], null);
  //       if (!targetName) {
  //         // @todo graph is invalid
  //         // isValid.valid = false;
  //         // isValid.errors.push(`associatedGraphs.${referencedGraphId} does not exist.`);
  //       } else {
  //         if (referencedNodeId !== undefined) {
  //           const newEdge = {
  //             edgeType: '',
  //             source: _id,
  //             target: `${targetName}-${referencedNodeId}`
  //           };
  //           fakeEdges.push(newEdge);
  //         } else {
  //           let target = `${targetName}-${rootNodeId}`;
  //           let foundTarget = find(nodes, ({ id }) => id === target);
  //           if (!foundTarget) {
  //             target = `${targetName}-${rootNodeId === '0' ? 'root' : '0'}`;
  //             foundTarget = find(nodes, ({ id }) => id === target);
  //           }
  //           const newEdge = {
  //             // @todo replace with actual rootNodeId
  //             edgeType: '',
  //             source: _id,
  //             target
  //           };
  //           fakeEdges.push(newEdge);
  //         }
  //       }
  //     }
  //   });
  // }

  // * add originalNode / link
  // * add some basic style props
  let _nodes = formatNodesForGraph([...nodes, ...fakeNodes]);
  let _edges = formatEdgesForGraph([...edges, ...fakeEdges]);

  // @todo optimize this, move outta here, into helper maybe? Use reduce maybe?
  const els = useMemo(() => {
    let ret = [];
    _nodes.forEach((node) => {
      ret.push({
        data: {
          ...node
        }
      });
    });
    _edges.forEach((edge) => {
      ret.push({
        data: {
          ...edge
        }
      });
    });
    return ret;
  }, [_nodes, _edges]);

  const stylesheet = useMemo(
    () => [
      {
        selector: 'edge',
        style: {
          label: function (element) {
            const originalEdge = element.data('originalEdge');
            return originalEdge.edgeType;
          }
        }
      },
      {
        selector: 'node',
        style: {
          label: function (element) {
            let label = '';
            const originalNode = element.data('originalNode');

            if (!nodeType[originalNode.nodeType]) {
              // console.warn(`Missing nodeType formatter for ${originalNode.nodeType}`);
            } else {
              label = nodeType[originalNode.nodeType](originalNode);
              // console.log(`Using formatter for ${originalNode.nodeType}`);
            }
            return label;
          }
        }
      }
    ],
    []
  );

  _nodes.forEach((node) => {
    const style = {
      selector: `node#${node.id}`,
      style: {
        backgroundColor: get(nodeColors, [node.nodeType, 'color'], 'gray'),
        borderColor: get(nodeColors, [node.nodeType, 'borderColor'], 'black'),
        borderWidth: get(nodeColors, [node.nodeType, 'borderWidth'], 1),
        color: theme.palette.text.primary,
        'text-halign': 'center',
        'text-justification': 'center',
        'text-max-width': 200,
        textWrap: 'wrap'
      }
    };
    const size = 100;
    const bgImage = urlMaker(node.assetDataUri || '', token);
    const type = mimeTypes.lookup(node.assetDataUri || '');

    // @todo this is not the best place but can we push datalake assets to a list here
    // and expose in the HMI? 

    if (type && type.indexOf('image/') === 0) {
      style.style.backgroundImage = bgImage;
      style.style.backgroundFit = 'contain';
      style.style.width = size;
      style.style.height = size;
    }
    // if (type && type.indexOf('json')) {
    //   // @todo display download link
    // }
    stylesheet.push(style);
  });

  stylesheet.push({
    selector: 'edge',
    style: {
      color: theme.palette.text.primary,
      'edge-text-rotation': 'autorotate',
      'line-color': theme.palette.text.primary,
      'line-opacity': 0.2
    }
  });

  const isValid = checkNodeIdsForEdgesExist(_nodes, _edges);

  const bindRef = useCallback((cy) => {
    cyRef.current = cy;
    cyRef.current.nodes().on('click', function (e) {
      // var clickedNode = e.target;
    });
  }, []);

  return useMemo(() => {
    if (!hasAnyData) return null;
    if (isValid.valid !== true) {
      return (
        <>
          <span>Invalid graph data:&nbsp;</span>
          <ul>
            {isValid.errors.map((error, idx) => (
              <li key={idx}>{error}&nbsp;</li>
            ))}
          </ul>
        </>
      );
    }
    return (
      <>
        {full && (
          <div
            ref={ref}
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%'
            }}
          >
            <div className={styles.validationBox}>
              {isValidAg === false && <ErrorOutlineIcon style={{ color: 'red' }} />}
              {isValidAg === undefined && <WarningIcon style={{ color: 'orange' }} />}
              {isValidAg === true && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
              {validationError}
            </div>
            <CytoscapeComponent
              id={id}
              stylesheet={stylesheet}
              layout={layout}
              elements={els}
              style={{ height, width }}
              cy={bindRef}
            />
          </div>
        )}
        {!full && (
          <CytoscapeComponent
            userZoomingEnabled={false}
            id={id}
            stylesheet={stylesheet}
            layout={layout}
            elements={els}
            style={{ border: '1px solid gray', height: 500, width: '100%' }}
            cy={bindRef}
          />
        )}
      </>
    );
  }, [
    hasAnyData,
    isValid.valid,
    isValid.errors,
    full,
    ref,
    isValidAg,
    validationError,
    id,
    stylesheet,
    layout,
    els,
    height,
    width,
    bindRef
  ]);
};

export default GraphViewCtyo;
