import { gql, useQuery } from "@apollo/client";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { CompetitionContext } from '../../contexts/CompetitionContext';

const QUERY = gql`
  {
    events {
      gym_id
      name
      competitions {
        gym_id
        name
        data_collection {
          name
          namespace
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const CompetitionSelect = (props) => {
  const classes = useStyles();

  const { data } = useQuery(QUERY, {
    fetchPolicy: "no-cache"
  });

  const handleChange = (event) => {
    const _competition = JSON.parse(event.target.value);
    setCompetition(_competition);
  };
  const { competition, setCompetition } = React.useContext(CompetitionContext);

  // @todo when namespace changes, set competition

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Used In Competition</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        key={competition}
        native
        value={competition ? JSON.stringify(competition) : ""}
        onChange={handleChange}
        variant="outlined"
      >
        <option aria-label="None" value={JSON.stringify({})} key="empty" />
        {data && data.events.length && data.events.map((e) => (
          <optgroup label={e.name} key={e.gym_id}>
            {e.competitions && e.competitions.length && e.competitions.map((c) => (
              <option key={c.gym_id} value={JSON.stringify(c)}>{c.name}</option>
            ))}
          </optgroup>
        ))}
        {/* {sortBy(competitions, 'id').map((c) => (
          <option key={c.id} value={c.id}>
            {c.id}: {c.metadata.labels.map((l) => `[${l}] `)}
            {c.metadata.title}
          </option>
        ))} */}
      </Select>
    </FormControl>
  );
};

export default CompetitionSelect;
