import { gql, useLazyQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Masonry from 'react-responsive-masonry';
import { useHistory, useParams } from 'react-router-dom';

import GallerySearch from '../GallerySearch';
import { useFilterStore } from '../GallerySearch/filterStore';
import GalleryTableRow from '../GalleryTableRow';

const QUERY = gql`
  query ArtifactGraph($offset: Int, $limit: Int, $filter_mongo: String, $where: ApiWhere) {
    artifactGraphs(offset: $offset, limit: $limit, filter_mongo: $filter_mongo, where: $where) {
      data {
        id
        name
        namespace
      }
    }
  }
`;

const useStyles = makeStyles({
  inner: {
    '&:hover': {
      boxShadow: '1px 1px 5px rgba(0,0,0,0.6)'
    },
    borderRadius: 5,
    boxShadow: '1px 1px 5px rgba(0,0,0,0.1)',
    margin: '0 10px 10px 0',
    minHeight: 100,
    transition: 'box-shadow 220ms ease'
  },
  pagination: {
    '&> button': {
      marginRight: 10
    },
    marginBottom: 10
  },
  row: {
    cursor: 'pointer',
    width: '100%'
  },
  selectedCard: {
    '& div': {
      border: '1px solid blue',
      dropShadow: '10px 10px 10px black'
    }
  },
  unstyled: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  }
});

const GalleryTable = (props) => {
  const { filter, fromUser, hasLocalizations, mmaOnly, modality } = useFilterStore();
  const { clientId } = useParams();
  const history = useHistory();
  const PAGE_SIZE = 15;
  const [selected] = useState();
  const classes = useStyles();
  const [items, setItems] = useState([]);

  // const [newFilter, setNewFilter] = useState('');
  // const [newModality, setNewModality] = useState('ALL');
  // const [isMMA, setIsMMA] = useState(false);
  const [offset, setOffset] = useState(0);
  // const [loading, setLoading] = useState(false);

  // const newFilterRef = useUpdatingRef(newFilter);
  // const newModalityRef = useUpdatingRef(newModality);
  // const isMMARef = useUpdatingRef(isMMA);
  // const offsetRef = useUpdatingRef(offset);
  // const loadingRef = useUpdatingRef(loading);
  const promiseRef = useRef();

  const [loadItems] = useLazyQuery(QUERY);

  const callback = useCallback(
    (variables) => {
      console.log('attempting load more...');
      if (promiseRef.current) {
        console.log('stopping, already loading...');
        return;
      }
      console.log('loading...', variables);
      // setLoading(true);
      promiseRef.current = loadItems({
        variables
      }).then(({ data }) => {
        console.log('loaded data');
        const _items = get(data, 'artifactGraphs.data', []);
        setItems((curr) => [...curr, ..._items]);
        setOffset((curr) => curr + PAGE_SIZE);
        promiseRef.current = undefined;
      });
    },
    [loadItems]
  );

  useEffect(() => {
    const variables = {
      filter_mongo: filter,
      limit: PAGE_SIZE,
      offset: 0,
      where: {
        from_user: fromUser,
        has_localizations: hasLocalizations,
        mma_only: mmaOnly,
        modality: modality
      }
    };
    setOffset(0);
    setItems([]);
    callback(variables);
  }, [callback, filter, fromUser, hasLocalizations, mmaOnly, modality]);

  // useLayoutEffect(() => {
  //   callback();
  // }, [callback]);

  useBottomScrollListener(
    () => {
      const variables = {
        filter_mongo: filter,
        limit: PAGE_SIZE,
        offset: offset + PAGE_SIZE,
        where: {
          from_user: fromUser,
          has_localizations: hasLocalizations,
          mma_only: mmaOnly,
          modality: modality
        }
      };
      callback(variables);
    },
    {
      offset: 200
    }
  );

  // const cancelLoading = () => {
  //   setLoading(false);
  //   if (promiseRef.current && isFunction(promiseRef.current.cancel)) {
  //     promiseRef.current.cancel();
  //   }
  // }

  // const resetAndQueryApi = ({ filter, mma, modality }) => {
  //   // set filtering things
  //   setNewFilter(filter);
  //   setIsMMA(mma);
  //   setNewModality(modality);
  //   // reset pagination state
  //   setOffset(0);
  //   setItems([]);
  //   // cancel loading and recall function
  //   cancelLoading();
  //   callback();
  // };

  return (
    <Box ml={2} mr={2}>
      {/* <div className={classes.pagination}>
          <Button
            disabled={offset === 0}
            variant="outlined"
            onClick={() => {
              setOffset((old) => old - PAGE_SIZE);
              fetchMore({ variables: { offset: offset } });
            }}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setOffset((old) => old + PAGE_SIZE);
              fetchMore({ variables: { offset: offset } });
            }}
          >
            Next
          </Button>
        </div> */}
      <Grid container>
        <Grid item xs={9}>
          <br />
          <Typography variant="h5">Media Gallery</Typography>
          <p>The following media has been processed with SemaFor analytics. You can click on any
            result to view more details.</p>
        </Grid>
        <Grid item xs={3}>
          {/* TODO: when count gets merged in, update to use endpoint */}
          {/* <Pagination count={Math.ceil(3800 / PAGE_SIZE)} /> */}
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              height: 50,
              position: 'relative',
              width: '100%'
            }}
          >
            <div style={{ position: 'absolute', width: '100%' }}>
              <GallerySearch
                onApply={() => {
                  // console.log(filter, mma, modality);
                  // console.log({ filter, mmaOnly, modality, setModality });
                  // resetAndQueryApi({
                  //   filter,
                  //   mma,
                  //   modality
                  // });
                }}
              />
            </div>
          </div>
          {/* <GallerySearch
            onApply={({ filter, mma, modality }) => {
              setIsMMA(mma);
              setNewFilter(filter);
              setNewModality(modality);
            }}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Masonry columnsCount={5}>
            {items.map((ag) => (
              <div
                key={ag.id}
                className={`${classes.row} ${
                  selected && selected.id === ag.id ? classes.selectedCard : null
                }`}
                onClick={() => {
                  history.push(`/${clientId}/gallery/${ag.namespace}/${ag.name}`);
                }}
              >
                <div className={classes.inner}>
                  <GalleryTableRow name={ag.name} namespace={ag.namespace} />
                </div>
              </div>
            ))}
          </Masonry>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GalleryTable;
