import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

// const TEMP_TABS = [
//   'Media Details',
//   'Artifact Graph 2',
//   'Artifact Graph 3',
//   'Artifact Graph 4',
//   'Artifact Graph 5',
//   'Artifact Graph 6',
//   'Artifact Graph 7',
//   'Artifact Graph 8',
//   'Artifact Graph 9',
//   'Artifact Graph 10',
//   'Artifact Graph 11',
//   'Artifact Graph 12'
// ];

const SemaForAppBar = styled(AppBar)(({ theme }) => ({
  '& > .MuiToolbar-gutters': {
    paddingLeft: 16,
    paddingRight: 16
  },
  background: '#292961',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp
  }),
  zIndex: theme.zIndex.drawer + 1
}));

const TabbedAppBar = () => {
  const { clientId } = useParams();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SemaForAppBar position="fixed">
        <Toolbar>
          <img src="/semaforLogo.ico" alt="SemaFor Logo" height="40px" width="40px" />
          <Link to={`/${clientId}/gallery`}>
            <IconButton color="inherit" sx={{ marginLeft: '10px' }}>
              <HomeIcon sx={{ color: 'white', fontSize: 30 }} />
            </IconButton>
          </Link>
          <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1 }}>
            {/* <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={selectedTab}
              onChange={(e, newVal) => {
                setSelectedTab(newVal);
              }}
            >
              {TEMP_TABS.map((tab) => (
                <Tab id={tab} sx={{ color: '#FFF', marginRight: 5 }} label={tab} />
              ))}
            </Tabs> */}
            {/* {TEMP_TABS.map((page) => (
              <Button
                key={page}
                sx={{ color: 'white', display: 'block', my: 2 }}
              >
                {page}
              </Button>
            ))} */}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton size="large" color="inherit" aria-label="menu">
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </SemaForAppBar>
    </Box>
  );
};

export default TabbedAppBar;
