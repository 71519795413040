import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { SettingsContext } from '../../../contexts/SettingsContext';
import { TokenContext } from '../../../contexts/TokenContext';
import safeParseApiJson from '../../../helpers/safeParseApiJson';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const WorkflowTable = (props) => {
  const classes = useStyles();
  const { connection } = useContext(SettingsContext);
  const { token } = useContext(TokenContext.Dynamic);
  const [page, setPage] = useState(0);
  const pageSize = 100;

  const fetchGraphs = ({ pageParam = undefined }) =>
    fetch(
      `${connection}/analyticworkflows?limit=${pageSize}&continueToken=${
        pageParam ? pageParam : ''
      }`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then((res) => res.text())
      .then(safeParseApiJson);

  const { data, error, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery(
    'analyticworkflows',
    fetchGraphs,
    {
      getNextPageParam: (lastPage) => get(lastPage, 'metadata.continueToken')
    }
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (isFetching) {
        console.warn('Wait until current request is complete to load next page');
        return;
      }
      if (newPage > page && hasNextPage) {
        fetchNextPage().then(() => {
          setPage(newPage);
        });
      } else {
        setPage(newPage);
      }
    },
    [fetchNextPage, page, hasNextPage, isFetching]
  );

  return useMemo(
    () => (
      <>
        {error && 'An error has occurred: ' + error.message}
        {!error && data && data.pages && data.pages.length > 0 && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={5}
                    count={Infinity} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Workflow</TableCell>
                  <TableCell>Namespace</TableCell>
                  {/* <TableCell>Generate Name</TableCell>
            <TableCell>Created Date</TableCell> */}
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell align="right">Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.pages[page].items.map((row) => (
                  <Row row={row} key={get(row, 'metadata.name')} />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={5}
                    count={Infinity} // @todo get from api once added
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </>
    ),
    [classes, data, error, page, handleChangePage]
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {get(row, 'metadata.name')}
        </TableCell>
        <TableCell>{get(row, 'metadata.namespace')}</TableCell>
        <TableCell>{get(row, 'status.state')}</TableCell>
        <TableCell>{get(row, 'status.reason')}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow key={`${get(row, 'metadata.name')}-phases`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Phases
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Records</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.status.phases.map((phase) => {
                    return (
                      <TableRow key={phase.name}>
                        <TableCell component="th" scope="row">
                          {phase.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {phase.state}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <ul>
                            {phase.componentsRecords.map((record) => {
                              return (
                                <li key={record.name}>
                                  Name: {record.name}
                                  <ul>
                                    <li>Reason: {record.reason}</li>
                                    <li>
                                      Output: {JSON.stringify(record.analyticOutput, null, 2)}
                                    </li>
                                    <li>
                                      Action Types:{' '}
                                      {JSON.stringify(record.responseActionTypes, null, 2)}
                                    </li>
                                    <li>State: {record.state}</li>
                                    <li>Tid: {record.tid}</li>
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default WorkflowTable;
